import { PermissionV2 } from '@breezy/shared'
import cn from 'classnames'
import React from 'react'
import { PermissionCategory } from './types'

const ToolTipText = React.memo(
  ({
    children,
    className,
  }: {
    children: React.ReactNode
    className?: string
  }) => {
    return (
      <div className={cn('flex flex-col gap-2', className)}>{children}</div>
    )
  },
)

export const PermissionDescriptions: Partial<
  Record<PermissionV2, React.ReactNode>
> = {
  [PermissionV2.USE_OFFICE_EXPERIENCE]:
    'Team members that have this permission enabled will be able to log in to the Desktop App.',
  [PermissionV2.USE_FIELD_EXPERIENCE]:
    'Team members that have this permission enabled will be able to log in to the Mobile App.',

  [PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED]:
    'Team member will be able to create custom discounts on estimates and invoices.',
  [PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED]:
    'Team member will be able to use AI to generate estimate descriptions.',

  [PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to manage customer information such as
        contacts, locations, and equipment as well as all historical information
        for jobs, appointments, estimates, invoices, and payments.
      </div>

      <div>
        We recommend this setting for CSRs, Dispatchers, and Department
        Managers.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW]: (
    <ToolTipText>
      <div>
        Team member will be able to view customer information such as contacts,
        locations, and equipment as well as all historical information for jobs,
        appointments, estimates, invoices, and payments.
      </div>
      <div>
        We recommend this setting for Marketing Managers and Bookkeepers.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to create, edit, and assign calendar items and
        appointments to team members.
      </div>
      <div>
        We recommend this setting for Dispatchers, Department Managers, and
        Field-Service Managers.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW]: (
    <ToolTipText>
      <div>
        Team member will be able to view the company calendar and scheduled
        appointments.
      </div>
      <div>
        We recommend this setting for back-office team members who should be
        able to view the company calendar but not schedule appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to manage all financial information, sync
        Quickbooks Online records (if QBO enabled), issue refunds, and view the
        financial dashboard.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should have access to view and manage company financial information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW]: (
    <ToolTipText>
      <div>Team member will be able to view the Estimates page.</div>
      <div>
        We recommend this setting for Bookkeepers and Accounts who need to be
        able to view company financials but should not be able to edit or manage
        financial information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW]: (
    <ToolTipText>
      <div>Team member will be able to view the Invoices page.</div>
      <div>
        We recommend this setting for Bookkeepers and Accounts who need to be
        able to view company financials but should not be able to edit or manage
        financial information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW]: (
    <ToolTipText>
      <div>Team member will be able to view the Payments page.</div>
      <div>
        We recommend this setting for Bookkeepers and Accounts who need to be
        able to view company financials but should not be able to edit or manage
        financial information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW]: (
    <ToolTipText>
      <div>Team member will be able to view the Payouts page.</div>
      <div>
        We recommend this setting for Bookkeepers and Accounts who need to be
        able to view company financials but should not be able to edit or manage
        financial information.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW]: (
    <ToolTipText>
      <div>
        Team member will be able to view performance data for the company's
        technicians
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to manage and edit data that affects the
        company's technician performance data
      </div>
    </ToolTipText>
  ),

  [PermissionV2.OFFICE_TIMESHEETS_VIEW]: (
    <ToolTipText>
      <div>Team member will be able to view the company's timesheets</div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_TIMESHEETS_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to view and edit the company's timesheets
      </div>
    </ToolTipText>
  ),

  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM]: (
    <ToolTipText>
      <div>
        Team member will be able to create and manage team members. This
        includes the ability to create new team members, edit existing team
        members, and assign permissions to team members.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage team member information and permissions.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK]: (
    <ToolTipText>
      <div>
        Team member will be able to create and manage the company pricebook.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage the company pricebook.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS]: (
    <ToolTipText>
      <div>
        Team member will be able to create and manage company maintenance plan
        settings.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage company maintenance plan settings.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS]: (
    <ToolTipText>
      <div>Team member will be able to adjust company hours.</div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage company hours.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES]: (
    <ToolTipText>
      <div>Team member will be able to create and manage job pipelines.</div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage job pipelines.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES]: (
    <ToolTipText>
      <div>
        Team member will be able to create and manage company job types.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage company job types.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS]: (
    <ToolTipText>
      <div>
        Team member will be able to create and manage appointment checklists.
      </div>
      <div>
        We recommend this setting for owners and other top-level team members
        who should be able to manage appointment checklists.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT]: (
    <ToolTipText>
      <div>
        Team member is allowed to connect their Breezy account to Zapier
      </div>
    </ToolTipText>
  ),

  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL]: (
    <ToolTipText>
      <div>
        Team member will be able to manage customer information such as
        contacts, locations, and equipment as well as all historical information
        for jobs, appointments, estimates, invoices, and payments for all
        accounts.
      </div>
      <div>
        We recommend this setting for owners and Field-Service Managers who
        should be able to manage all customer information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL]: (
    <ToolTipText>
      <div>
        Team member will be able to manage customer information for their
        related assignments. They will also be able to lookup and view all
        customer information for all other accounts.
      </div>
      <div>
        We recommend this setting for Field-Service Managers and technicians who
        should be able to view but not manage all customer information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED]: (
    <ToolTipText>
      <div>
        Team member will be able to manage customer information such as
        contacts, locations, and equipment as well as all historical information
        for jobs, appointments, estimates, invoices, and payments for their
        related assignments.
      </div>
      <div>
        We recommend this setting for technicians who should be able to update
        customer information for assigned appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED]: (
    <ToolTipText>
      <div>
        Team member will be able to view customer information such as contacts,
        locations, and equipment as well as all historical information for jobs,
        appointments, estimates, invoices, and payments for their related
        assignments.
      </div>
      <div>
        We recommend this setting for technicians who should only be able to
        view but not update customer information for assigned appointments.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.FIELD_JOB_INFORMATION_MANAGE_FULL]: (
    <ToolTipText>
      <div>
        Team member will be able to create and edit job information including
        estimates, invoices, job details, notes, photos, and attachments for all
        jobs.
      </div>
      <div>
        We recommend this setting for owners and Field-Service Managers who
        should be able to manage all job information.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED]: (
    <ToolTipText>
      <div>
        Team member will be able to create and edit job information including
        estimates, invoices, job details, notes, photos, and attachments for
        their related assignments.
      </div>
      <div>
        We recommend this setting for technicians who should be able to manage
        job information for assigned appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_JOB_INFORMATION_CREATE]: (
    <ToolTipText>
      <div>Team member will be able to create new jobs.</div>
      <div>
        We recommend this setting for technicians who should be able to create
        new jobs.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.FIELD_SCHEDULE_CREATE]: (
    <ToolTipText>
      <div>
        Team member will be able to create new appointments and miscellaneous
        schedule events
      </div>
      <div>
        We recommend this setting for owners and Field-Service Managers who
        should be able manage appointment assignments and scheduling for all
        other technicians.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to view, update the status, and update the
        checklist of all of their current and future assigned appointments.
      </div>
      <div>
        We recommend this setting for technicians who should be able to view all
        of their assigned appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY]: (
    <ToolTipText>
      <div>
        Team member will be able to view, update the status, and update the
        checklist of all of their current and future assigned appointments for
        the current business day.
      </div>
      <div>
        We recommend this setting for technicians who should be able to view all
        of their assigned appointments for only the current business day.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT]: (
    <ToolTipText>
      <div>
        Team member will only be able to view, update the status, and update the
        checklist of their current or next assigned appointment for the current
        business day.
      </div>
      <div>
        We recommend this setting for technicians who should only be able to
        view their current or next assigned appointment for the current business
        day.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED]: (
    <ToolTipText>
      <div>
        Team member will be able to view, update the status, and update the
        checklist of all of their previously assigned appointments for the
        previous 30 days.
      </div>
      <div>
        We recommend this setting for technicians who should be able to view all
        of their past assigned appointments for the previous 30 days.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE]: (
    <ToolTipText>
      <div>
        Team member will be able to view, update the status, and update the
        checklist of all other technician appointments.
      </div>
      <div>
        We recommend this setting for Field-Service Managers who should be able
        to manage all other technician appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW]: (
    <ToolTipText>
      <div>
        Team member will be able to view all other technician appointments.
      </div>
      <div>
        We recommend this setting for Field-Service Managers and technicians who
        should be able to view but not manage all other technician appointments.
      </div>
    </ToolTipText>
  ),
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE]: (
    <ToolTipText>
      <div>
        Team member will not be able to view other technician appointments.
      </div>
      <div>
        We recommend this setting for technicians who should not be able to view
        other technician appointments.
      </div>
    </ToolTipText>
  ),

  [PermissionV2.OFFICE_INTEGRATED_PHONE]: (
    <ToolTipText>
      <div>
        Team member will be able to use the integrated phone system within the
        Desktop App.
      </div>
      <div>
        We recommend this setting for team members who need to make and receive
        calls through the app.
      </div>
    </ToolTipText>
  ),
}

export const PermissionV2DisplayNames: Partial<
  Record<PermissionV2, React.ReactNode>
> = {
  // Office
  [PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE]: 'Full Access',
  [PermissionV2.OFFICE_ACCOUNTS_JOBS_VIEW]: 'View only access',

  [PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_MANAGE]: 'Full Access',
  [PermissionV2.OFFICE_SCHEDULE_APPOINTMENTS_VIEW]: 'View only access',

  [PermissionV2.OFFICE_REPORTS_MANAGE]: 'Full Access',
  [PermissionV2.OFFICE_REPORTS_VIEW]: 'View only access',

  [PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW]: 'View only access',
  [PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_MANAGE]: 'Full Access',

  [PermissionV2.OFFICE_TIMESHEETS_VIEW]: 'View only access',
  [PermissionV2.OFFICE_TIMESHEETS_MANAGE]: 'Full Access',

  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE]: 'Full Access',
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_ESTIMATES_VIEW]: 'Estimates',
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW]: 'Invoices',
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW]: 'Payments',
  [PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYOUTS_VIEW]: 'Payouts',

  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM]: 'Team',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK]: 'Pricebook',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS]:
    'Maintenance Plans',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS]: 'Company Hours',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES]: 'Job Pipelines',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES]: 'Job Types',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS]: 'Checklists',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS]: 'Tags',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS]: 'Notifications',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING]:
    'Drip Marketing',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_APPOINTMENT_SETTINGS]:
    'Appointment Configuration',
  [PermissionV2.OFFICE_INTEGRATION_ZAPIER_ACCOUNT_CONNECT]: 'Zapier',
  [PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES]: 'Full Access',

  // Field
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL]: 'Full Access',
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL]:
    'Manage account & contact records related to assigned appointments. View only access to all other accounts & contacts',
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_ASSIGNED]:
    'Manage account & contact records related to assigned appointments',
  [PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_ASSIGNED]:
    'View only access to account & contact records related to assigned appointments',

  [PermissionV2.FIELD_JOB_INFORMATION_MANAGE_FULL]: 'Full Access',
  [PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED]:
    'Manage jobs related to assigned appointments',
  [PermissionV2.FIELD_JOB_INFORMATION_CREATE]: 'Allow user to create new jobs',

  [PermissionV2.FIELD_SCHEDULE_CREATE]:
    'Allow user to create new appointments and schedule events',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE]:
    'All of their assigned appointments',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_TODAY]:
    'Only their assigned appointments for the current day',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT]:
    'Only their current or next assigned appointment for the current day',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_VIEW_HISTORICAL_ASSIGNED]:
    'Allow user to see their appointments from the previous 30 days',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_NONE]: 'No access',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_MANAGE]: 'Full Access',
  [PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_TEAM_VIEW]: 'View only access',

  [PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED]:
    'Custom Discounts',
  [PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED]:
    'AI Estimate Descriptions',

  [PermissionV2.OFFICE_INTEGRATED_PHONE]: 'Full Access',
}

export const PermissionCategoryLabels: Record<PermissionCategory, string> = {
  OFFICE_ACCOUNTS_JOBS: 'Accounts & jobs',
  OFFICE_SCHEDULE_APPOINTMENTS: 'Schedule & appointments',
  OFFICE_REPORTS: 'Reports',
  OFFICE_FINANCIAL_INFORMATION: 'Financial Information',
  OFFICE_TECHNICIAN_PERFORMANCE: 'Technician Performance',
  OFFICE_TIMESHEETS: 'Timesheets',
  OFFICE_COMPANY_SETTINGS: 'Company Settings',
  OFFICE_INTEGRATIONS: 'Integrations',
  OFFICE_LEAD_SOURCES: 'Lead Sources',

  FIELD_ACCOUNTS_CONTACTS: 'Accounts & contacts',
  FIELD_JOB_INFORMATION: 'Job information',
  FIELD_SCHEDULE_APPOINTMENTS: 'Schedule & appointments',

  OFFICE_INTEGRATED_PHONE: 'Integrated Phone',
}

export const PermissionCategoryDescriptions: Record<
  PermissionCategory,
  string
> = {
  OFFICE_ACCOUNTS_JOBS:
    'Account and job detail pages display customer information such as contacts, locations, and equipment as well as all historical information for jobs, appointments, estimates, invoices, and payments.',
  OFFICE_SCHEDULE_APPOINTMENTS:
    'This includes access to the Schedule to view or manage calendar items and technician appointments.',
  OFFICE_FINANCIAL_INFORMATION:
    'This includes access to Estimates, Invoices, Payouts, Payments, certain Dashboard visualizations, and the Quickbooks Online Integration.',
  OFFICE_COMPANY_SETTINGS:
    'This allows team members to make changes to the settings options for the team, pricebook, maintenance plans, and more.',
  OFFICE_REPORTS: 'Manage or view reports in the desktop app.',
  OFFICE_INTEGRATIONS:
    'Manage what integrations this user is allowed to connect to',
  OFFICE_LEAD_SOURCES:
    'Manages if the user can view and edit lead sources or not',

  OFFICE_TECHNICIAN_PERFORMANCE:
    'Manage who can view and edit technician performance data',
  OFFICE_TIMESHEETS: 'Manage who can view and edit timesheets',

  FIELD_ACCOUNTS_CONTACTS:
    'Manage and view accounts and contacts in the mobile app.',
  FIELD_JOB_INFORMATION: 'Manage and view job information in the mobile app.',
  FIELD_SCHEDULE_APPOINTMENTS:
    'Manage and view schedule and appointments in the mobile app.',

  OFFICE_INTEGRATED_PHONE:
    'Manage access to the integrated phone system within the Desktop App.',
}
