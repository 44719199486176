import { PageHeader } from '@ant-design/pro-components'
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import { Page } from '../../components/Page/Page'
import { Card } from '../../elements/Card/Card'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { useIsEndOfAppointmentWorkflowEnabledForCompany } from '../../hooks/fetch/useIsEndOfAppointmentWorkflowEnabledForCompany'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { AppointmentConfigurationSettingsV1Form } from './AppointmentConfigurationSettingsV1Form'
import { AppointmentConfigurationSettingsV2Page } from './AppointmentConfigurationSettingsV2Form'

export const AppointmentConfigurationSettingsPage = React.memo(() => {
  const isEndOfAppointmentWorkflowEnabledForCompany =
    useIsEndOfAppointmentWorkflowEnabledForCompany()

  const endOfVisitSendReviewLinkEnabled = useFeatureFlag('end-of-visit-review')

  return (
    <Page requiresCompanyUser className="flex flex-col p-0">
      <PageHeader
        title={<PageTitle title="Visit Settings" icon={faCalendarCheck} />}
      />
      <Card className="min-h-0 w-full overflow-auto p-2">
        {endOfVisitSendReviewLinkEnabled ? (
          <AppointmentConfigurationSettingsV2Page />
        ) : (
          <AppointmentConfigurationSettingsV1Form
            isEndOfAppointmentWorkflowEnabledForCompany={
              isEndOfAppointmentWorkflowEnabledForCompany
            }
          />
        )}
      </Card>
    </Page>
  )
})
