import { isNullish } from '@breezy/shared/src'
import { appointmentConfigurationSettingsV1FormSchema } from '@breezy/shared/src/domain/Appointments/Appointments'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { z } from 'zod'
import {
  CategorySection,
  CheckField,
  SubSection,
} from '../../components/Settings/SettingsBuilderComponents'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED } from './AppointmentConfigurationSettingsPage.gql'

type AppointmentConfigurationSettingsV1FormData = z.infer<
  typeof appointmentConfigurationSettingsV1FormSchema
>

interface AppointmentConfigurationSettingsV1FormProps {
  isEndOfAppointmentWorkflowEnabledForCompany: boolean | null | undefined
}

export const AppointmentConfigurationSettingsV1Form: React.FC<
  AppointmentConfigurationSettingsV1FormProps
> = ({ isEndOfAppointmentWorkflowEnabledForCompany }) => {
  const companyGuid = useExpectedCompanyGuid()

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
  } = useForm<AppointmentConfigurationSettingsV1FormData>({
    resolver: zodResolver(appointmentConfigurationSettingsV1FormSchema),
    defaultValues: appointmentConfigurationSettingsV1FormSchema.parse({}),
  })

  useEffect(() => {
    if (!isNullish(isEndOfAppointmentWorkflowEnabledForCompany)) {
      reset({
        endOfAppointmentWorkflowEnabled:
          isEndOfAppointmentWorkflowEnabledForCompany,
      })
    }
  }, [isEndOfAppointmentWorkflowEnabledForCompany, reset])

  const [{ fetching: submitting }, executeMutation] = useMutation(
    UPDATE_END_OF_APPOINTMENT_WORKFLOW_ENABLED,
  )

  const onSubmit = handleSubmit(data => {
    executeMutation({
      companyGuid,
      enabled: data.endOfAppointmentWorkflowEnabled,
    })
  })

  return (
    <Form onSubmitCapture={onSubmit} layout="vertical">
      <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
        <div className="max-w-[512px] text-bz-gray-900">
          Enable or Disable certain features related to appointments
        </div>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!isDirty}
        >
          Save Changes
        </Button>
      </div>
      <div className="divide-y divide-solid divide-bz-gray-400">
        <CategorySection
          title="Technician Assignments"
          subtitle="Configure settings related to technician assignments"
        >
          <SubSection
            title="End of Appointment Next Steps Form"
            subtitle="Technicians are required to submit a next steps form and a note for the dispatcher before closing the appointment."
          >
            <CheckField
              defaultValue={false}
              errors={errors}
              name="endOfAppointmentWorkflowEnabled"
              control={control}
              disabled={submitting}
              label={`Enable End of Appointment Next Steps Form`}
            />
          </SubSection>
        </CategorySection>
      </div>
    </Form>
  )
}
