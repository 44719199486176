import {
  AppointmentType,
  AssignableApptViewModelWithBusinessProjections,
  AssignmentDTO,
  AssignmentWithTechnicianNameDTO,
  BzAddress,
  BzDateFns,
  BzTimeWindow,
  DateTimeFormatter,
  ESTIMATED_EQUIPMENT_AGE_OPTIONS,
  InferredAppointmentStatus,
  IsoDateString,
  OfficeRoutes,
  R,
  TimeZoneId,
  User,
  UserGuid,
  ZoneId,
  ZonedDateTime,
  calculateInferredAppointmentStatus,
  dates,
  eligibleToBeAssignedToJobAppointments,
  enrichWithPlaceholderBusinessProjections,
  nextGuid,
  toPlural,
  toRoleDisplayName,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { faEllipsis, faWrench } from '@fortawesome/pro-light-svg-icons'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowRight,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Modal, Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { DELETE_ASSIGNMENT_MUTATION } from '../../components/CancelAppointmentModal/CancelAppointmentModal.gql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import UpsertAppointmentDrawer from '../../components/NewAppointmentModal/UpsertAppointmentDrawer'
import { AppointmentDetailsDrawer } from '../../components/Scheduler/FullAppointmentDetailsDrawer/FullAppointmentDetailsDrawer'
import { TechnicianResource } from '../../components/Scheduler/scheduleUtils'
import { Card, CardBody } from '../../elements/Card/Card'
import { Link } from '../../elements/Link/Link'
import { ReadUpcomingAppointmentsQuery } from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useGetMapAppUrl } from '../../hooks/useMapLink'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { getAvatarShortStringForPerson } from '../../utils/TechnicianResource'
import { userColor } from '../../utils/color-utils'
import { AppointmentStatusTag } from '../ScheduleV2Page/AppointmentStatusTag'
import { NoDataOverlay } from './NoDataOverlay'
import { READ_UPCOMING_APPOINTMENTS_QUERY } from './ReportingDashboard.gql'
import { DUMMY_BACKGROUND_UPCOMING_APPOINTMENTS_DATA } from './dummyBackgroundData'
import { RefetchableAppointmentsRef, useRefetchableAppointments } from './utils'

// NOTE: this is based on the hard-coded card height defined in ReportingDashboardPage.tsx. This was substantially
// easier than deciding based on container height. If the card height changes this will likely need to be changed too.
const NUM_ROWS = 4

const CELL_CLASS = 'py-3.5 px-4'

const getFormattedDateRange = (
  start: IsoDateString,
  end: IsoDateString,
  tzId: TimeZoneId,
): [day: string, range: string] => {
  const now = BzDateFns.now(tzId)
  const tomorrow = BzDateFns.getTomorrow(tzId)

  const range = dates.calculateDateTimeWindow(start, end, tzId, {
    includeDate: false,
    alwaysShowMinutes: true,
  })

  const startDate = BzDateFns.parseISO(start, tzId)

  let day = ''
  if (BzDateFns.isSameDay(startDate, now)) {
    day = 'Today'
  } else if (BzDateFns.isSameDay(startDate, tomorrow)) {
    day = 'Tomorrow'
  } else {
    day = BzDateFns.format(startDate, 'E')
  }

  return [day, range]
}

type AvatarCircleProps = React.PropsWithChildren<{
  popoverContent?: React.ReactNode
  className?: string
}>

const AvatarCircle = React.memo<AvatarCircleProps>(
  ({ children, popoverContent, className }) => {
    const content = (
      <div
        className={classNames(
          'flex h-10 w-10 items-center justify-center rounded-full border-2 border-solid border-bz-gray-100 bg-bz-gray-300 font-semibold text-bz-gray-800',
          className,
        )}
      >
        {children}
      </div>
    )

    if (popoverContent) {
      return <Popover content={popoverContent}>{content}</Popover>
    }
    return content
  },
)

type Tech = Pick<
  TechnicianResource,
  | 'avatarColor'
  | 'avatarShortString'
  | 'userGuid'
  | 'deactivatedAt'
  | 'firstName'
  | 'lastName'
  | 'emailAddress'
  | 'phoneNumbers'
  | 'roles'
> & {
  formattedScheduledDay: string
  formattedScheduledDateRange: string
}

type UserCircleProps = {
  user: Tech
  noPopover?: boolean
}

const UserCircle = React.memo<UserCircleProps>(({ user, noPopover }) => (
  <AvatarCircle
    popoverContent={noPopover ? undefined : <TechInfo tech={user} />}
    className={user.deactivatedAt ? 'opacity-50' : undefined}
  >
    {user.avatarShortString}
  </AvatarCircle>
))

type TechInfoProps = {
  tech: Tech
}

const TechInfo = React.memo<TechInfoProps>(({ tech }) => (
  <div>
    <div className="font-semibold">
      {tech.firstName} {tech.lastName}
    </div>
    <div className="text-bz-gray-900">
      Scheduled for {tech.formattedScheduledDay}
      <br />
      {tech.formattedScheduledDateRange}
    </div>
  </div>
))

const viewAllJobsButton = (
  <Link to="/schedule" className="text-sm">
    View Dispatch Board
    <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
  </Link>
)

export type UpcomingAppointmentWidgetAppointment =
  ReadUpcomingAppointmentsQuery['jobAppointments'][number] & {
    formattedDay: string
    formattedDateRange: string
    appointmentCanceled: boolean
    appointmentStatus: InferredAppointmentStatus
    technicians: Tech[]
  }

const assignmentsToAssignmentDtos = (
  assignments: UpcomingAppointmentWidgetAppointment['assignments'],
): AssignmentWithTechnicianNameDTO[] =>
  assignments.map(assignment => ({
    ...assignment,
    assignmentStatus:
      assignment.assignmentStatus?.jobAppointmentAssignmentStatusType ??
      'TO_DO',
    technicianUserGuid: assignment.technician.userGuid,
    technicianFirstName: assignment.technician.firstName,
    technicianLastName: assignment.technician.lastName,
    timeWindow: {
      start: assignment.assignmentStart,
      end: assignment.assignmentEnd,
    },
  }))

type UpcomingAppointmentsTableProps = {
  refetch: () => unknown
  data: UpcomingAppointmentWidgetAppointment[]
  showLastItemBorder?: boolean
  availableTechnicians: User[]
  extraWide?: boolean
}

const UpcomingAppointmentsTable = React.memo<UpcomingAppointmentsTableProps>(
  ({ data, refetch, showLastItemBorder, availableTechnicians, extraWide }) => {
    const companyGuid = useExpectedCompanyGuid()

    const tzId = useExpectedCompanyTimeZoneId()
    const [
      editAppointmentModalAppointment,
      setEditAppointmentModalAppointment,
    ] = useState<UpcomingAppointmentWidgetAppointment>()
    const onAppointmentEdited = useCallback(() => {
      setEditAppointmentModalAppointment(undefined)
      refetch()
    }, [refetch])
    const onEditAppointmentCancel = useCallback(
      () => setEditAppointmentModalAppointment(undefined),
      [],
    )

    const getMapAppUrl = useGetMapAppUrl()

    const [
      selectedAppointmentModalAppointment,
      setSelectedAppointmentModalAppointment,
    ] = useState<{
      appt: AssignableApptViewModelWithBusinessProjections
      technicians?: Pick<
        User,
        | 'userGuid'
        | 'firstName'
        | 'lastName'
        | 'roles'
        | 'deactivatedAt'
        | 'emailAddress'
        | 'phoneNumbers'
      >[]
    }>()

    const onDetailsClick = useCallback(
      ({
        job,
        technicians,
        assignments,
        ...appointment
      }: UpcomingAppointmentWidgetAppointment) => {
        setSelectedAppointmentModalAppointment({
          appt: enrichWithPlaceholderBusinessProjections({
            ...job,
            assignments: assignmentsToAssignmentDtos(assignments),
            contact: {
              ...job.pointOfContact,
              accountGuid: job.accountGuid,
              name: `${job.pointOfContact.firstName} ${job.pointOfContact.lastName}`,
              phoneNumber:
                job.pointOfContact.primaryPhoneNumber?.phoneNumber ?? '',
              email: job.pointOfContact.primaryEmailAddress?.emailAddress ?? '',
              phoneNumberType:
                job.pointOfContact.primaryPhoneNumber?.type ?? 'HOME',
            },
            location: {
              companyGuid,
              ...job.location,
            },
            appointment: {
              ...appointment,
              guid: appointment.appointmentGuid,
              referenceNumber: appointment.appointmentReferenceNumber,
              appointmentConfirmed: !!appointment.confirmationStatus?.confirmed,
              timeWindow: {
                start: appointment.appointmentWindowStart,
                end: appointment.appointmentWindowEnd,
              },
              endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(
                appointment.endOfAppointmentNextSteps,
              ),
              appointmentStatus: appointment.appointmentStatus,
            },
            jobInvoices: [],
            accountTags: [],
            completedChecklists: [],
            maintenancePlans: [],
          }),
          technicians,
        })
      },
      [companyGuid],
    )

    const comprehensiveAppointment = useMemo(() => {
      if (!editAppointmentModalAppointment) {
        return undefined
      }
      const zone = ZoneId.of(tzId)
      return {
        timeWindow: new BzTimeWindow(
          ZonedDateTime.parse(
            editAppointmentModalAppointment.appointmentWindowStart,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(zone),
          ZonedDateTime.parse(
            editAppointmentModalAppointment.appointmentWindowEnd,
            DateTimeFormatter.ISO_OFFSET_DATE_TIME,
          ).withZoneSameInstant(zone),
        ),
        appointmentType:
          editAppointmentModalAppointment.appointmentType as AppointmentType,
        description: editAppointmentModalAppointment.description,
        endOfAppointmentNextSteps: tryParseEndOfAppointmentNextSteps(
          editAppointmentModalAppointment.endOfAppointmentNextSteps,
        ),
        appointmentStatus: editAppointmentModalAppointment.appointmentStatus,
        assignments: editAppointmentModalAppointment.assignments.map(
          assignment => ({
            assignmentGuid: assignment.assignmentGuid,
            technicianUserGuid: assignment.technician.userGuid,
            timeWindow: new BzTimeWindow(
              ZonedDateTime.parse(
                assignment.assignmentStart,
                DateTimeFormatter.ISO_OFFSET_DATE_TIME,
              ).withZoneSameInstant(zone),
              ZonedDateTime.parse(
                assignment.assignmentEnd,
                DateTimeFormatter.ISO_OFFSET_DATE_TIME,
              ).withZoneSameInstant(zone),
            ),
          }),
        ),
      }
    }, [editAppointmentModalAppointment, tzId])

    const noData = !data.length

    const resolvedAppointments = useMemo(
      () => (noData ? DUMMY_BACKGROUND_UPCOMING_APPOINTMENTS_DATA : data),
      [data, noData],
    )

    const navigate = useNavigate()

    const onCreateJobClick = useCallback(
      () => navigate(OfficeRoutes.JOB_CREATION.path),
      [navigate],
    )

    const addAssignmentMutation =
      trpc.scheduling['scheduling:assign'].useMutation()

    const addTechniciansToSelectedAppointment = useCallback(
      async (userGuids: UserGuid[]) => {
        if (!selectedAppointmentModalAppointment) {
          return
        }
        const firstAssignment =
          selectedAppointmentModalAppointment.appt.assignments[0]

        for (const technicianUserGuid of userGuids) {
          addAssignmentMutation.mutate(
            {
              jobGuid: selectedAppointmentModalAppointment.appt.jobGuid,
              jobAppointmentGuid:
                selectedAppointmentModalAppointment.appt.appointment.guid,
              assignmentGuid: nextGuid(),
              technicianUserGuid,
              timeWindow: firstAssignment.timeWindow,
            },
            {
              onSuccess: () => {
                // NOTE: because of tRPC magic, even though I do this n times for each userGuid, it will only happen
                // once once the last one finishes.
                refetch()
              },
            },
          )
        }
      },
      [addAssignmentMutation, refetch, selectedAppointmentModalAppointment],
    )

    const [, removeAssignmentMutation] = useMutation(DELETE_ASSIGNMENT_MUTATION)

    // TODO: right now you can add several techs at once in the sidebar and hit "save". Doing so closes the side bar
    // (because we do the refetch). Deleting does the same thing but you can only do one at a time. This might be
    // annoying, but fixing it is a pain. You'd have to maintain a list of pending deleted techs, transform the
    // appointment that goes in (so they "see" the change in the sidebar) then commit the change when they close the
    // sidebar. But if they navigate after deleting then the change doesn't persist...
    const removeAssignmentFromSelectedAppointment = useCallback(
      async (assignment: AssignmentDTO) => {
        await removeAssignmentMutation({
          jobAppointmentAssignmentGuid: assignment.assignmentGuid,
        })
        refetch()
      },
      [refetch, removeAssignmentMutation],
    )

    return (
      <>
        <div className="max-w-full overflow-x-auto">
          <table className="w-full max-w-full table-auto">
            <thead>
              <tr className="h-5">
                {[
                  'Appointment',
                  'Arrival Window',
                  'Status',
                  'Equip. Age (Yrs)',
                  'Team',
                ].map((header, i) => (
                  <th
                    key={header}
                    className={classNames(
                      'px-4 pb-4 text-left text-sm font-semibold uppercase text-bz-gray-800',
                      {
                        'pl-6': extraWide && i === 0,
                        'pr-6': extraWide && i === 4,
                      },
                    )}
                  >
                    {header}
                  </th>
                ))}
                {/* For the "more" menu */}
                <th className="w-[60px] pr-4" />
              </tr>
            </thead>
            <tbody>
              {resolvedAppointments.map(appointment => {
                const unassigned = !appointment.technicians.length

                const validTechnicians = appointment.technicians.filter(
                  user => !user.deactivatedAt,
                )

                const oldestEquipment = R.sort(
                  // ESTIMATED_EQUIPMENT_AGE_OPTIONS is in order from youngest to oldest, with the exception of "Unknown
                  // age" being last. Given multiple pieces of equipment, we want the oldest one. So we'll sort them by age
                  // descending and take the first one. To get the ages, we'll take the index in
                  // ESTIMATED_EQUIPMENT_AGE_OPTIONS (smaller index means younger). However, if the age is unknown (either
                  // it's literally "Unknown Age" or undefined) we want to de-prioritize it and show a real age if possible.
                  // So set that "index" to -1 which will always be first and, thus, last with descending order.
                  R.descend(equipment => {
                    if (
                      !equipment.estimatedEquipmentAge ||
                      equipment.estimatedEquipmentAge === 'Unknown age'
                    ) {
                      return -1
                    }
                    return ESTIMATED_EQUIPMENT_AGE_OPTIONS.indexOf(
                      equipment.estimatedEquipmentAge as (typeof ESTIMATED_EQUIPMENT_AGE_OPTIONS)[number],
                    )
                  }),
                  appointment.job.equipmentTypeJobLinks,
                )[0]

                return (
                  <tr
                    key={appointment.appointmentGuid}
                    className={classNames(
                      'max-w-full border-0 border-t border-solid text-sm',
                      unassigned
                        ? 'border-b border-y-bz-red-200 bg-bz-red-100 text-bz-red-700 hover:border-y-bz-red-300'
                        : 'border-y-bz-gray-400 hover:bg-bz-gray-200',
                      showLastItemBorder ? 'last:border-b' : 'last:border-b-0',
                    )}
                  >
                    <td
                      className={classNames(
                        CELL_CLASS,
                        'text-bz-gray-900',
                        extraWide ? 'pl-6' : 'pl-4',
                      )}
                    >
                      <div className="flex flex-row items-center">
                        <div
                          className={classNames(
                            'flex h-9 w-9 min-w-[36px] items-center justify-center rounded-full ',
                            unassigned ? 'bg-bz-red-200' : 'bg-bz-gray-300',
                          )}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className={classNames(
                              'h-4',
                              unassigned
                                ? 'text-bz-red-600'
                                : 'text-bz-gray-600',
                            )}
                          />
                        </div>
                        <div className="ml-3">
                          <div>
                            <Link onClick={() => onDetailsClick(appointment)}>
                              {appointment.appointmentType}
                              {' for '}
                              {appointment.job.jobType.name}
                            </Link>
                          </div>
                          <div>
                            <Link
                              bold={false}
                              className="overflow-hidden text-ellipsis text-inherit hover:underline"
                              to={`/accounts/${appointment.job.accountGuid}`}
                            >
                              {appointment.job.account.accountDisplayName}
                            </Link>
                            <span className="text-bz-gray-600"> • </span>
                            <Link
                              bold={false}
                              to={`/locations/${appointment.job.location.locationGuid}`}
                              className="text-inherit hover:underline"
                            >
                              {BzAddress.formatAddressLine1And2Condensed(
                                appointment.job.location.address,
                              )}
                            </Link>
                            <span className="text-bz-gray-600"> • </span>
                            <a
                              className="whitespace-nowrap text-inherit hover:underline"
                              href={getMapAppUrl(
                                appointment.job.location.address,
                              )}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Maps ↗
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className={CELL_CLASS}>
                      {appointment.formattedDay},{' '}
                      {appointment.formattedDateRange}
                    </td>
                    <td className={CELL_CLASS}>
                      <AppointmentStatusTag
                        status={appointment.appointmentStatus}
                      />
                    </td>
                    <td className={CELL_CLASS}>
                      {oldestEquipment?.estimatedEquipmentAge ?? 'Unknown age'}
                    </td>
                    <td className={classNames(CELL_CLASS, 'pr-4')}>
                      {unassigned ? (
                        <div
                          className="flex cursor-pointer flex-row items-center font-semibold text-bz-red-700 hover:underline"
                          onClick={() =>
                            setEditAppointmentModalAppointment(appointment)
                          }
                        >
                          <div className="mr-1">
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className="text-base"
                            />
                          </div>
                          <div className="whitespace-nowrap">Assign Tech</div>
                        </div>
                      ) : (
                        <div className="flex flex-row space-x-[-6px]">
                          {validTechnicians.slice(0, 2).map(tech => (
                            <UserCircle key={tech.userGuid} user={tech} />
                          ))}
                          {validTechnicians.length > 2 ? (
                            <AvatarCircle
                              popoverContent={
                                <div className="divide-y divide-bz-gray-400 pr-2">
                                  {validTechnicians.slice(2).map(tech => (
                                    <div
                                      key={tech.userGuid}
                                      className="mt-3 flex flex-row items-center space-x-3 border-0 border-solid pt-3 first:mt-0 first:pt-0"
                                    >
                                      <UserCircle user={tech} />
                                      <TechInfo tech={tech} />
                                    </div>
                                  ))}
                                </div>
                              }
                            >
                              +{validTechnicians.length - 2}
                            </AvatarCircle>
                          ) : null}
                        </div>
                      )}
                    </td>
                    <td
                      className={classNames(
                        'w-[60px]',
                        extraWide ? 'pr-6' : 'pr-4',
                      )}
                    >
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: 'edit',
                              label: 'Edit Appointment',
                              onClick: () =>
                                setEditAppointmentModalAppointment(appointment),
                            },
                            {
                              key: 'viewJob',
                              label: 'View Job',
                              onClick: () =>
                                navigate(`/jobs/${appointment.job.jobGuid}`),
                            },
                            {
                              key: 'viewAccount',
                              label: 'View Account',
                              onClick: () =>
                                navigate(
                                  `/accounts/${appointment.job.accountGuid}`,
                                ),
                            },
                          ],
                        }}
                      >
                        <Button
                          icon={
                            <FontAwesomeIcon
                              icon={faEllipsis}
                              className="text-2xl"
                            />
                          }
                          type="text"
                        />
                      </Dropdown>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {noData && (
          //  Why "top-[-8px]? The blur needs to be a certain strength or it looks bad. However, at that strength, the
          //  table headers start to cut off because they blur too high. So scooch this up a bit to blend in white above
          //  the header.
          <NoDataOverlay
            className="top-[-8px] pt-2"
            icon={faWrench}
            title="No upcoming appointments"
            link={<Link onClick={onCreateJobClick}>Create job</Link>}
          >
            Create a job or schedule an appointment to an existing job.
          </NoDataOverlay>
        )}
        {comprehensiveAppointment && editAppointmentModalAppointment && (
          <UpsertAppointmentDrawer
            mode="edit"
            appointmentGuid={editAppointmentModalAppointment.appointmentGuid}
            comprehensiveAppointment={comprehensiveAppointment}
            open
            onCancel={onEditAppointmentCancel}
            onAppointmentEdited={onAppointmentEdited}
            jobGuid={editAppointmentModalAppointment.job.jobGuid}
            jobClass={editAppointmentModalAppointment.job.jobType.jobClass}
          />
        )}
        {selectedAppointmentModalAppointment && (
          <AppointmentDetailsDrawer
            assignable={selectedAppointmentModalAppointment}
            availableTechnicians={availableTechnicians}
            onClose={() => {
              setSelectedAppointmentModalAppointment(undefined)
              refetch()
            }}
            removeAssignment={removeAssignmentFromSelectedAppointment}
            addTechnicians={addTechniciansToSelectedAppointment}
          />
        )}
      </>
    )
  },
)

type UpcomingAppointmentsWidgetProps = {
  className?: string
  refetchAppointments: () => unknown
  refetchableAppointmentsRef: React.Ref<RefetchableAppointmentsRef>
}
export const UpcomingAppointmentsWidget =
  React.memo<UpcomingAppointmentsWidgetProps>(
    ({ className, refetchAppointments, refetchableAppointmentsRef }) => {
      const tzId = useExpectedCompanyTimeZoneId()

      const [startWindow, endWindow] = useMemo(() => {
        const today = BzDateFns.getToday(tzId)
        const threeDaysFromNow = BzDateFns.addDays(today, 2)
        const eodThreeDaysFromNow = BzDateFns.endOfDay(threeDaysFromNow)

        return [
          BzDateFns.formatISO(today, tzId),
          BzDateFns.formatISO(eodThreeDaysFromNow, tzId),
        ]
      }, [tzId])

      const [{ data: rawAppointmentsData, fetching }, refetch] = useQuery({
        query: READ_UPCOMING_APPOINTMENTS_QUERY,
        variables: {
          startWindow,
          endWindow,
        },
      })

      useRefetchableAppointments(refetchableAppointmentsRef, refetch)

      const usersQuery = trpc.user['users:get'].useQuery()

      const availableTechnicians = useMemo(
        () =>
          usersQuery.data?.users
            .filter(user => !user.deactivatedAt)
            .filter(user =>
              eligibleToBeAssignedToJobAppointments(user.schedulingCapability),
            ) ?? [],
        [usersQuery.data],
      )

      const data = useMemo<UpcomingAppointmentWidgetAppointment[]>(() => {
        return (
          rawAppointmentsData?.jobAppointments.map(appointment => {
            const appointmentCanceled =
              !!appointment.cancellationStatus?.canceled

            const [day, range] = getFormattedDateRange(
              appointment.appointmentWindowStart,
              appointment.appointmentWindowEnd,
              tzId,
            )

            return {
              ...appointment,
              appointmentCanceled,
              appointmentStatus: calculateInferredAppointmentStatus(
                appointmentCanceled,
                assignmentsToAssignmentDtos(appointment.assignments),
              ),
              technicians: appointment.assignments.map(assignment => {
                const { technician, assignmentStart, assignmentEnd } =
                  assignment

                const [day, range] = getFormattedDateRange(
                  assignmentStart,
                  assignmentEnd,
                  tzId,
                )

                return {
                  ...technician,
                  phoneNumbers: R.pluck('phoneNumber', technician.phoneNumbers),
                  roles: technician.roles.map(({ role }) => ({
                    role,
                    name: toRoleDisplayName(role),
                  })),
                  avatarShortString: getAvatarShortStringForPerson(technician),
                  avatarColor: userColor(technician.userGuid),
                  formattedScheduledDay: day,
                  formattedScheduledDateRange: range,
                }
              }),
              formattedDay: day,
              formattedDateRange: range,
            }
          }) ?? []
        )
      }, [rawAppointmentsData?.jobAppointments, tzId])

      const [showAllOpen, setShowAllOpen] = useState(false)

      const truncatedData = useMemo(() => data.slice(0, NUM_ROWS), [data])

      const hasNoDataOverlay = !data.length

      return (
        <Card
          title="3-Day Schedule"
          className={classNames(
            'h-[428px] min-h-[428px] overflow-hidden',
            className,
          )}
          bodyClassName="mx-[-16px] mb-[-16px] "
          hideTitleDivider
          titleAction={!hasNoDataOverlay && viewAllJobsButton}
        >
          {fetching || usersQuery.isLoading ? (
            <LoadingSpinner />
          ) : (
            // Why HTML table and not Ant design table? I basically need no functionality and very specific styles. I
            // started doing the antd table and found that I was disabling (or omitting) all its special features
            // (pagination, sorting, etc) and then going to extra effort to customize the styling (overriding the header
            // styles, row height, etc). I eventually decided it was much easier and straightforward to just use a plain,
            // good ol' fashioned HTML table.
            <>
              <UpcomingAppointmentsTable
                data={truncatedData}
                refetch={refetchAppointments}
                showLastItemBorder={data.length > NUM_ROWS}
                availableTechnicians={availableTechnicians}
              />
              {data.length > NUM_ROWS && (
                <div className="ml-16 py-4">
                  <Link onClick={() => setShowAllOpen(true)}>
                    View {data.length - NUM_ROWS} more{' '}
                    {toPlural(data.length - NUM_ROWS, 'appointment')}
                  </Link>
                </div>
              )}
            </>
          )}
          <Modal
            open={showAllOpen}
            closeIcon={false}
            onCancel={() => setShowAllOpen(false)}
            footer={null}
            width="75%"
          >
            <CardBody
              title="Upcoming Appointments"
              hideTitleDivider
              titleAction={viewAllJobsButton}
              className="mx-[-24px] mt-[-1rem]"
            >
              <UpcomingAppointmentsTable
                extraWide
                data={data}
                availableTechnicians={availableTechnicians}
                refetch={refetchAppointments}
              />
            </CardBody>
          </Modal>
        </Card>
      )
    },
  )
