import { isNullish, PermissionV2 } from '@breezy/shared'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Checkbox,
  Collapse,
  Divider,
  Radio,
  RadioChangeEvent,
  Space,
  Switch,
  Tooltip,
} from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import cn from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import './PermissionControls.less'
import {
  PermissionCategoryDescriptions,
  PermissionCategoryLabels,
  PermissionDescriptions,
  PermissionV2DisplayNames,
} from './PermissionControlsText'
import {
  getCapabilityAiEstimateDescriptionsEnabled,
  getCapabilityCustomDiscountCreationEnabled,
  getCheckablePermissions,
  getFieldExperienceEnabled,
  getOfficeExperienceEnabled,
  getPermissionCategoryNestedPermissionGroupVisibleValue,
  getPermissionCategoryPermissionGroup,
  getPermissionCategoryPermissionGroupRankedPermissions,
  getPermissionCategoryPermissionGroupVisiblePermission,
  getPermissionGroupCheckablePermissions,
  getPermissionGroupCheckedPermissions,
  getSelectedPermissions,
} from './getters'
import {
  isCheckablePermissionGroup,
  PermissionCategory,
  RankedPermission,
} from './types'
import { useIsCategoryEnabled } from './useIsCategoryEnabled'
import { usePermissionControls } from './usePermissionControls'

type PermissionCategoryItem = {
  key: PermissionCategory | 'OFFICE_EXPERIENCE' | 'FIELD_EXPERIENCE'
  label: React.ReactNode
  children: React.ReactNode
  extra: React.ReactNode
  collapsible?: 'header' | 'disabled' | 'icon'
}

const PermissionControls = React.memo(() => {
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const { state, toggleOfficeAppPermissions, toggleFieldAppPermissions } =
    usePermissionControls()
  const isOfficeAppEnabled = useMemo(
    () => getOfficeExperienceEnabled(state),
    [state],
  )
  const isFieldAppEnabled = useMemo(
    () => getFieldExperienceEnabled(state),
    [state],
  )
  const onCollapseToggle = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKeys(key)
    } else {
      setActiveKeys([key])
    }
  }, [])

  const onCategorySwitch = useCallback(
    (
      checked: boolean,
      permissionCategory: 'OFFICE_EXPERIENCE' | 'FIELD_EXPERIENCE',
    ) => {
      setActiveKeys(
        checked
          ? [...activeKeys, permissionCategory]
          : activeKeys.filter(k => k !== permissionCategory),
      )
    },
    [activeKeys],
  )

  return (
    <div className="col">
      <PermissionCategoryCollapsible
        open={activeKeys.length > 0}
        onChange={onCollapseToggle}
        activeKeys={activeKeys}
        className={cn(
          'top-level-collapse',
          !isOfficeAppEnabled && 'office-app-disabled',
          !isFieldAppEnabled && 'field-app-disabled',
        )}
        items={[
          {
            key: 'OFFICE_EXPERIENCE',
            label: (
              <div className="flex flex-row items-center gap-2">
                Desktop App
                {!isOfficeAppEnabled && (
                  <Tooltip
                    title={
                      PermissionDescriptions[PermissionV2.USE_OFFICE_EXPERIENCE]
                    }
                  >
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </Tooltip>
                )}
              </div>
            ),
            collapsible: isOfficeAppEnabled ? 'header' : 'disabled',
            children: (
              <>
                <OfficeAccountsJobsPermissionCategory />
                <OfficeScheduleAppointmentsPermissionCategory />
                <OfficeFinancialInformationPermissionCategory />
                <BehindFeatureFlag
                  enabledFeatureFlag="selfServeLeadSources"
                  render={<OfficeLeadSourcesPermissionCategory />}
                  fallback={null}
                />
                <OfficeTimesheetsPermissionCategory />
                <OfficeTechnicianPerformanceCategory />
                <OfficeCompanySettingsPermissionCategory />
                <OfficeIntegratedPhoneCategory />
                <BehindFeatureFlag
                  enabledFeatureFlag="zapier"
                  render={<OfficeIntegrationsPermissionsCategory />}
                  fallback={null}
                />
              </>
            ),
            extra: (
              <PermissionCategorySwitch
                checked={isOfficeAppEnabled}
                onChange={checked => {
                  onCategorySwitch(checked, 'OFFICE_EXPERIENCE')
                  toggleOfficeAppPermissions(checked)
                }}
              />
            ),
          },
          {
            key: 'FIELD_EXPERIENCE',
            label: (
              <div className="flex flex-row items-center gap-2">
                Mobile Technician App
                {!isFieldAppEnabled && (
                  <Tooltip
                    title={
                      PermissionDescriptions[PermissionV2.USE_FIELD_EXPERIENCE]
                    }
                  >
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </Tooltip>
                )}
              </div>
            ),
            collapsible: isFieldAppEnabled ? 'header' : 'disabled',
            children: (
              <>
                <FieldAccountsContactsPermissionCategory />
                <Divider className="m-0" />
                <FieldJobInformationPermissionCategory />
                <Divider className="m-0" />
                <FieldScheduleAppointmentsPermissionCategory />
              </>
            ),
            extra: (
              <PermissionCategorySwitch
                checked={isFieldAppEnabled}
                onChange={checked => {
                  onCategorySwitch(checked, 'FIELD_EXPERIENCE')
                  toggleFieldAppPermissions(checked)
                }}
              />
            ),
          },
        ]}
      />
      <div className="row flex w-full">
        <CapabilitiesCollapsible open={true} onChange={() => {}} />
      </div>
    </div>
  )
})

const PermissionCategoryToolTip = React.memo<{
  permissionCategory: PermissionCategory
}>(({ permissionCategory }) => {
  return (
    <Tooltip title={PermissionCategoryDescriptions[permissionCategory]}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  )
})

const PermissionToolTip = React.memo<{
  permission: PermissionV2
}>(({ permission }) => {
  return (
    <Tooltip title={PermissionDescriptions[permission]}>
      <FontAwesomeIcon icon={faCircleQuestion} />
    </Tooltip>
  )
})

const PermissionCategoryLabel = React.memo<{
  permissionCategory: PermissionCategory
  showLabel?: boolean
}>(({ permissionCategory, showLabel = true }) => {
  return (
    <div className="flex flex-row items-center gap-3">
      {PermissionCategoryLabels[permissionCategory]}
      {showLabel && (
        <PermissionCategoryToolTip permissionCategory={permissionCategory} />
      )}
    </div>
  )
})

const OfficeAccountsJobsPermissionCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_ACCOUNTS_JOBS')
  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  return (
    <PermissionCategoryCollapsible
      open={open}
      onChange={onChange}
      disabled={!isCategoryEnabled}
      className="nested-collapse"
      items={[
        {
          key: 'OFFICE_ACCOUNTS_JOBS',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_ACCOUNTS_JOBS"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-12"
              permissionCategory="OFFICE_ACCOUNTS_JOBS"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_ACCOUNTS_JOBS',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeScheduleAppointmentsPermissionCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_SCHEDULE_APPOINTMENTS')

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  return (
    <PermissionCategoryCollapsible
      open={open}
      onChange={onChange}
      disabled={!isCategoryEnabled}
      className="nested-collapse"
      items={[
        {
          key: 'OFFICE_SCHEDULE_APPOINTMENTS',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_SCHEDULE_APPOINTMENTS"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-12"
              permissionCategory="OFFICE_SCHEDULE_APPOINTMENTS"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_SCHEDULE_APPOINTMENTS',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeFinancialInformationPermissionCategory = React.memo(() => {
  const {
    state,
    togglePermissionCategoryPermissions,
    selectOfficeFinancialInformationNestedPermission,
    checkedNestedPermissionsOfficeFinancialInformation,
  } = usePermissionControls()
  const [open, setOpen] = useState(false)
  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_FINANCIAL_INFORMATION')

  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      selectOfficeFinancialInformationNestedPermission(e.target.value)
    },
    [selectOfficeFinancialInformationNestedPermission],
  )
  const onCollapseChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const visibleValue = useMemo(() => {
    return getPermissionCategoryNestedPermissionGroupVisibleValue(
      state,
      'OFFICE_FINANCIAL_INFORMATION',
      0,
    )
  }, [state])

  const rankedPermissions = useMemo(
    () =>
      getPermissionCategoryPermissionGroupRankedPermissions(
        state,
        'OFFICE_FINANCIAL_INFORMATION',
        0,
      ),
    [state],
  )

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onCollapseChange}
      disabled={!isCategoryEnabled}
      items={[
        {
          key: 'OFFICE_FINANCIAL_INFORMATION',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_FINANCIAL_INFORMATION"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <NestedPermissionGroup
              permissionCategory="OFFICE_FINANCIAL_INFORMATION"
              permissionGroupIndex={0}
              visibleValue={visibleValue}
              onRadioChange={onRadioChange}
              rankedPermissions={rankedPermissions}
              onCheckedPermissionsChange={
                checkedNestedPermissionsOfficeFinancialInformation
              }
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_FINANCIAL_INFORMATION',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const NestedPermissionGroup = React.memo<{
  permissionCategory: PermissionCategory
  permissionGroupIndex: number
  visibleValue: number | PermissionV2
  onRadioChange: (e: RadioChangeEvent) => void
  rankedPermissions: RankedPermission[]
  onCheckedPermissionsChange: (permissions: PermissionV2[]) => void
}>(
  ({
    permissionCategory,
    permissionGroupIndex,
    visibleValue,
    onRadioChange,
    rankedPermissions,
    onCheckedPermissionsChange,
  }) => {
    const { state } = usePermissionControls()

    const nestedCheckedPermissions = useMemo(
      () =>
        getSelectedPermissions(
          getPermissionCategoryPermissionGroup(
            state,
            permissionCategory,
            permissionGroupIndex,
          ),
        ),
      [state, permissionCategory, permissionGroupIndex],
    )

    return (
      <Radio.Group
        className={'pl-12'}
        value={visibleValue}
        onChange={onRadioChange}
      >
        <Space direction="vertical">
          {rankedPermissions.map(r => {
            if (!r.permission && r.childPermissionGroups) {
              const isPhantomRadioSelection = visibleValue === r.rank
              return (
                <React.Fragment key={r.rank}>
                  <Radio value={r.rank}>{r.label}</Radio>
                  {isPhantomRadioSelection &&
                    r.childPermissionGroups.map((cpg, i) => {
                      if (isCheckablePermissionGroup(cpg)) {
                        return (
                          <PermissionCheckboxGroup
                            key={`${i}_${JSON.stringify(cpg)}`}
                            className="pl-5"
                            checkablePermissions={getCheckablePermissions(cpg)}
                            checkedPermissions={nestedCheckedPermissions}
                            onPermissionsChange={(
                              permissions: PermissionV2[],
                            ) => onCheckedPermissionsChange(permissions)}
                          />
                        )
                      }

                      return null
                    })}
                </React.Fragment>
              )
            }

            return (
              <Radio key={r.permission} value={r.permission ?? r.rank}>
                <div className="flex flex-row items-center gap-2">
                  {r.permission
                    ? PermissionV2DisplayNames[r.permission]
                    : r.label ?? ''}
                  {r.permission && PermissionDescriptions[r.permission] && (
                    <PermissionToolTip permission={r.permission} />
                  )}
                </div>
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    )
  },
)

const OfficeCompanySettingsPermissionCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_COMPANY_SETTINGS')

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      disabled={!isCategoryEnabled}
      items={[
        {
          key: 'OFFICE_COMPANY_SETTINGS',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_COMPANY_SETTINGS"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupCheckboxGroup
              className="pl-10"
              permissionCategory="OFFICE_COMPANY_SETTINGS"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_COMPANY_SETTINGS',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeIntegrationsPermissionsCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_INTEGRATIONS')

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      items={[
        {
          key: 'OFFICE_INTEGRATIONS',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_INTEGRATIONS"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupCheckboxGroup
              className="pl-10"
              permissionCategory="OFFICE_INTEGRATIONS"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_INTEGRATIONS',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeTechnicianPerformanceCategory = memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled(
    'OFFICE_TECHNICIAN_PERFORMANCE',
  )

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      items={[
        {
          key: 'OFFICE_TECHNICIAN_PERFORMANCE',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_TECHNICIAN_PERFORMANCE"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-10"
              permissionCategory="OFFICE_TECHNICIAN_PERFORMANCE"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_TECHNICIAN_PERFORMANCE',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeTimesheetsPermissionCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_TIMESHEETS')

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      items={[
        {
          key: 'OFFICE_TIMESHEETS',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_TIMESHEETS"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-10"
              permissionCategory="OFFICE_TIMESHEETS"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_TIMESHEETS',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeIntegratedPhoneCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_INTEGRATED_PHONE')

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      items={[
        {
          key: 'OFFICE_INTEGRATED_PHONE',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_INTEGRATED_PHONE"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-10"
              permissionCategory="OFFICE_INTEGRATED_PHONE"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_INTEGRATED_PHONE',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const OfficeLeadSourcesPermissionCategory = React.memo(() => {
  const { togglePermissionCategoryPermissions } = usePermissionControls()
  const [open, setOpen] = useState(false)

  const onChange = useCallback((key: string | string[]) => {
    if (Array.isArray(key)) {
      setOpen(key.length > 0)
    } else {
      setOpen(true)
    }
  }, [])

  const isCategoryEnabled = useIsCategoryEnabled('OFFICE_LEAD_SOURCES')

  return (
    <PermissionCategoryCollapsible
      open={open}
      className="nested-collapse"
      onChange={onChange}
      items={[
        {
          key: 'OFFICE_LEAD_SOURCES',
          label: (
            <PermissionCategoryLabel
              permissionCategory="OFFICE_LEAD_SOURCES"
              showLabel={!isCategoryEnabled}
            />
          ),
          children: (
            <PermissionGroupRadioGroup
              className="pl-10"
              permissionCategory="OFFICE_LEAD_SOURCES"
              permissionGroupIndex={0}
            />
          ),
          extra: (
            <PermissionCategorySwitch
              checked={isCategoryEnabled}
              onChange={checked => {
                togglePermissionCategoryPermissions(
                  'OFFICE_LEAD_SOURCES',
                  checked,
                )
                setOpen(checked)
              }}
            />
          ),
        },
      ]}
    />
  )
})

const FieldAccountsContactsPermissionCategory = React.memo(() => {
  return (
    <PermissionCategorySection>
      <PermissionCategoryTitle permissionCategory="FIELD_ACCOUNTS_CONTACTS" />
      <PermissionGroupRadioGroup
        className="px-10"
        permissionCategory="FIELD_ACCOUNTS_CONTACTS"
        permissionGroupIndex={0}
      />
    </PermissionCategorySection>
  )
})

const FieldJobInformationPermissionCategory = React.memo(() => {
  return (
    <PermissionCategorySection>
      <PermissionCategoryTitle permissionCategory="FIELD_JOB_INFORMATION" />
      <PermissionGroupRadioGroup
        className="px-10"
        permissionCategory="FIELD_JOB_INFORMATION"
        permissionGroupIndex={0}
      />
      <PermissionGroupCheckboxGroup
        className="px-8 pt-3"
        permissionCategory="FIELD_JOB_INFORMATION"
        permissionGroupIndex={1}
      />
    </PermissionCategorySection>
  )
})

const FieldScheduleAppointmentsPermissionCategory = React.memo(() => {
  return (
    <PermissionCategorySection>
      <PermissionCategoryTitle permissionCategory="FIELD_SCHEDULE_APPOINTMENTS" />
      <div className="flex flex-col gap-3 px-10">
        <div className="flex flex-col gap-1">
          <div className="font-semibold">Their appointments</div>
          <PermissionGroupRadioGroup
            permissionCategory="FIELD_SCHEDULE_APPOINTMENTS"
            permissionGroupIndex={0}
          />
          <PermissionGroupCheckboxGroup
            className="-ml-2 mt-2"
            permissionCategory="FIELD_SCHEDULE_APPOINTMENTS"
            permissionGroupIndex={1}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="font-semibold">Other technician appointments</div>
          <PermissionGroupRadioGroup
            permissionCategory="FIELD_SCHEDULE_APPOINTMENTS"
            permissionGroupIndex={2}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="font-semibold">Scheduling</div>
          <PermissionGroupCheckboxGroup
            permissionCategory="FIELD_SCHEDULE_APPOINTMENTS"
            permissionGroupIndex={3}
          />
        </div>
        <div className="mt-3 flex italic">
          Note: Technicians will have access to view historical jobs for any
          account they are assigned or have permissions to view. This allows
          technicians to be informed about any previous work completed for a
          customer.
        </div>
      </div>
    </PermissionCategorySection>
  )
})

const PermissionCategorySection = React.memo<{
  children: React.ReactNode
}>(({ children }) => {
  return <div className="flex flex-col pb-3 pl-6">{children}</div>
})

const PermissionCategoryTitle = React.memo<{
  permissionCategory: PermissionCategory
}>(({ permissionCategory }) => {
  return (
    <div className="flex flex-row items-center gap-3 px-4 py-3 font-semibold">
      {PermissionCategoryLabels[permissionCategory]}
    </div>
  )
})

const PermissionCategorySwitch = React.memo<{
  checked: boolean
  onChange: SwitchChangeEventHandler
}>(({ checked, onChange }) => {
  return <Switch checked={checked} onChange={onChange} />
})

const CapabilitiesCollapsible = React.memo<{
  open: boolean
  onChange: (key: string | string[]) => void
  disabled?: boolean
  activeKeys?: string[]
  className?: string
}>(({ onChange, disabled = false, activeKeys, className }) => {
  const {
    state,
    toggleCapabilityCustomDiscountCreationEnabled,
    toggleCapabilityAiEstimateDescriptionsEnabled,
  } = usePermissionControls()
  const permissions = [
    PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
    PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED,
  ]

  const checkedPermissions = useMemo(() => {
    return [
      getCapabilityCustomDiscountCreationEnabled(state)
        ? PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED
        : null,
      getCapabilityAiEstimateDescriptionsEnabled(state)
        ? PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED
        : null,
    ].filter(p => !isNullish(p))
  }, [state])

  return (
    <Collapse
      className={cn(
        'mt-4',
        'w-full',
        'permission-category-collapse',
        'no-global-border-radius',
        'bold-collapse-header-text',
        !disabled ? 'bg-inherit' : '',
        className,
      )}
      collapsible={disabled ? 'disabled' : 'header'}
      defaultActiveKey={[]}
      activeKey={activeKeys}
      items={[
        {
          key: 'CAPABILITIES',
          label: (
            <div className="flex flex-row items-center gap-2">Capabilities</div>
          ),
          children: [
            <PermissionCheckboxGroup
              checkablePermissions={permissions}
              checkedPermissions={checkedPermissions}
              onPermissionsChange={permissions => {
                toggleCapabilityCustomDiscountCreationEnabled(
                  permissions.includes(
                    PermissionV2.CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED,
                  ),
                )

                toggleCapabilityAiEstimateDescriptionsEnabled(
                  permissions.includes(
                    PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED,
                  ),
                )
              }}
              className="px-4"
            />,
          ],
        },
      ]}
      onChange={onChange}
    />
  )
})

const PermissionCategoryCollapsible = React.memo<{
  open: boolean
  items: PermissionCategoryItem[]
  onChange: (key: string | string[]) => void
  disabled?: boolean
  activeKeys?: string[]
  className?: string
}>(({ open, items, onChange, disabled = false, activeKeys, className }) => {
  const inferredActiveKeys = useMemo(() => {
    return open ? items.map(i => i.key) : []
  }, [open, items])

  return (
    <Collapse
      className={cn(
        'permission-category-collapse',
        'no-global-border-radius',
        'bold-collapse-header-text',
        !disabled ? 'bg-inherit' : '',
        className,
      )}
      collapsible={disabled ? 'disabled' : 'header'}
      defaultActiveKey={[]}
      activeKey={activeKeys ?? inferredActiveKeys}
      items={items}
      onChange={onChange}
    />
  )
})

const PermissionGroupRadioGroup = React.memo<{
  permissionCategory: PermissionCategory
  permissionGroupIndex: number
  className?: string
}>(({ permissionCategory, permissionGroupIndex, className }) => {
  const { state, setPermissionCategoryPermission } = usePermissionControls()

  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setPermissionCategoryPermission(
        permissionCategory,
        e.target.value,
        permissionGroupIndex,
      )
    },
    [permissionCategory, permissionGroupIndex, setPermissionCategoryPermission],
  )

  const value = useMemo(
    () =>
      getPermissionCategoryPermissionGroupVisiblePermission(
        state,
        permissionCategory,
        permissionGroupIndex,
      ),
    [state, permissionCategory, permissionGroupIndex],
  )

  const rankedPermissions = useMemo(
    () =>
      getPermissionCategoryPermissionGroupRankedPermissions(
        state,
        permissionCategory,
        permissionGroupIndex,
      ),
    [state, permissionCategory, permissionGroupIndex],
  )

  return (
    <Radio.Group className={className} value={value} onChange={onChange}>
      <Space direction="vertical">
        {rankedPermissions.map(r => {
          return (
            <Radio key={r.permission} value={r.permission}>
              <div className="flex flex-row items-center gap-2">
                {r.permission
                  ? PermissionV2DisplayNames[r.permission]
                  : r.label ?? ''}
                {r.permission && PermissionDescriptions[r.permission] && (
                  <PermissionToolTip permission={r.permission} />
                )}
              </div>
            </Radio>
          )
        })}
      </Space>
    </Radio.Group>
  )
})

const PermissionGroupCheckboxGroup = React.memo<{
  permissionCategory: PermissionCategory
  permissionGroupIndex: number
  className?: string
}>(({ permissionCategory, permissionGroupIndex, className }) => {
  const { state, checkedPermissionsPermissionCategory } =
    usePermissionControls()

  const onChecked = useCallback(
    (checkedValues: CheckboxValueType[]) => {
      checkedPermissionsPermissionCategory(
        permissionCategory,
        checkedValues as PermissionV2[],
        permissionGroupIndex,
      )
    },
    [
      permissionCategory,
      permissionGroupIndex,
      checkedPermissionsPermissionCategory,
    ],
  )

  const checkedPermissions = useMemo(
    () =>
      getPermissionGroupCheckedPermissions(
        state,
        permissionCategory,
        permissionGroupIndex,
      ),
    [state, permissionCategory, permissionGroupIndex],
  )

  const checkablePermissions = useMemo(
    () =>
      getPermissionGroupCheckablePermissions(
        state,
        permissionCategory,
        permissionGroupIndex,
      ),
    [state, permissionCategory, permissionGroupIndex],
  )

  return (
    <PermissionCheckboxGroup
      checkablePermissions={checkablePermissions}
      checkedPermissions={checkedPermissions}
      onPermissionsChange={onChecked}
      className={className}
    />
  )
})

const PermissionCheckboxGroup = React.memo<{
  checkablePermissions: PermissionV2[]
  checkedPermissions: PermissionV2[]
  onPermissionsChange: (permissions: PermissionV2[]) => void
  className?: string
}>(
  ({
    checkablePermissions,
    checkedPermissions,
    onPermissionsChange,
    className,
  }) => {
    const onChecked = useCallback(
      (checkedValues: CheckboxValueType[]) => {
        onPermissionsChange(checkedValues as PermissionV2[])
      },
      [onPermissionsChange],
    )

    return (
      <Checkbox.Group
        value={checkedPermissions}
        onChange={onChecked}
        className={className}
      >
        <Space direction="vertical">
          {checkablePermissions.map(permission => {
            return (
              <Checkbox key={permission} value={permission}>
                <div className="flex flex-row items-center gap-2">
                  {PermissionV2DisplayNames[permission]}
                  {PermissionDescriptions[permission] && (
                    <PermissionToolTip permission={permission} />
                  )}
                </div>
              </Checkbox>
            )
          })}
        </Space>
      </Checkbox.Group>
    )
  },
)

export default PermissionControls
