import {
  CalculatePaths,
  getMapAppUrl,
  JOB_LEAD_STATUS_ARCHIVED,
  JOB_LEAD_STATUS_CONVERTED,
  JOB_LEAD_STATUS_OPEN,
} from '@breezy/shared'
import {
  faArchive,
  faCalendarCirclePlus,
  faCopy,
} from '@fortawesome/pro-light-svg-icons'
import { faCheck, faMessageDollar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, message } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'urql'
import CopyToClipboard from '../../elements/CopyToClipboard/CopyToClipboard'
import { TimeAgo } from '../../elements/TimeAgo/TimeAgo'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { JobAvailabilitiesView } from './JobLeadAvailabilityView'
import { UPDATE_JOB_LEAD_STATUS } from './JobLeads.gql'
import { NotCapturedView } from './NotCapturedView'
import { JobLead } from './types'
import { jobLeadsHeader } from './utils'

type ItemProps = React.PropsWithChildren<{
  title: React.ReactNode
  className?: string
  rightContent?: React.ReactNode
}>

export const Item = React.memo<ItemProps>(
  ({ title, children, className, rightContent }) => (
    <div className={classNames('', className)}>
      <div className="mb-1 flex flex-row items-center">
        <div className="flex-1 text-sm font-semibold">{title}</div>
        {rightContent}
      </div>
      <div>{children ? children : <NotCapturedView />}</div>
    </div>
  ),
)

type JobLeadViewProps = {
  jobLead: JobLead
  refetch: () => void | Promise<void>
  onConvertJob: (jobLead: JobLead) => void
}

export const JobLeadView = React.memo<JobLeadViewProps>(
  ({ jobLead, refetch, onConvertJob }) => {
    const companyGuid = useExpectedCompanyGuid()

    const [{ fetching: isMutatingJobLeadStatus }, executeMutation] =
      useMutation(UPDATE_JOB_LEAD_STATUS)

    const onClickArchive = useCallback(async () => {
      await executeMutation({
        companyGuid,
        jobLeadGuid: jobLead.jobLeadGuid,
        newStatus: JOB_LEAD_STATUS_ARCHIVED,
      })

      message.success(`Lead Archived`)

      refetch()
    }, [companyGuid, executeMutation, jobLead.jobLeadGuid, refetch])

    const addressToken = useMemo(() => {
      const addr = [
        jobLead.serviceAddressLine1 && jobLead.serviceAddressLine2
          ? `${jobLead.serviceAddressLine1} ${jobLead.serviceAddressLine2}`
          : jobLead.serviceAddressLine1,
        jobLead.serviceAddressCity,
        jobLead.serviceAddressStateAbbreviation,
        jobLead.serviceAddressZipCode,
      ]
        .map(str => str?.trim())
        .filter(Boolean)
        .join(', ')

      if (!addr || addr.trim().length === 0) return null
      return addr
    }, [
      jobLead.serviceAddressCity,
      jobLead.serviceAddressLine1,
      jobLead.serviceAddressLine2,
      jobLead.serviceAddressStateAbbreviation,
      jobLead.serviceAddressZipCode,
    ])

    const jobLeadHeader = useMemo(() => jobLeadsHeader(jobLead), [jobLead])

    const onClickConvertToJob = useCallback(() => {
      onConvertJob(jobLead)
    }, [jobLead, onConvertJob])

    return (
      <div className="job-leads-container flex w-full p-4">
        <div className="job-leads-container-icon-container w-15 h-15 flex-shrink-0">
          <div className="self-start">
            {(jobLead.jobLeadStatus === JOB_LEAD_STATUS_OPEN ||
              jobLead.jobLeadStatus === JOB_LEAD_STATUS_ARCHIVED) && (
              <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-gray-300 p-3">
                <FontAwesomeIcon
                  icon={faMessageDollar}
                  className="mt-0.5 h-[18px] w-[16px] text-bz-gray-600"
                />
              </div>
            )}
            {jobLead.jobLeadStatus === JOB_LEAD_STATUS_CONVERTED && (
              <div className="flex h-[36px] w-[36px] items-center justify-center rounded-full bg-bz-green-200 p-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="mt-0.5 h-[18px] w-[16px] text-bz-green-600"
                />
              </div>
            )}
          </div>
        </div>
        <div className="job-leads-container-main-content mx-4 flex-1">
          <div className="job-leads-container-header flex-1 text-base font-semibold">
            {jobLeadHeader}
          </div>
          <div className="job-leads-container-summary mt-3">
            <Item title="Summary">{jobLead.jobSummary}</Item>
          </div>
          <div className="job-leads-container-availability-and-address mt-3 flex">
            <div className="job-leads-container-availability flex-1">
              <Item title="Availability">
                <JobAvailabilitiesView jobLead={jobLead} />
              </Item>
            </div>
            <div className="job-leads-container-address flex-1">
              <Item title="Service Address">
                {addressToken ? (
                  <a
                    href={getMapAppUrl(
                      {
                        line1: jobLead.serviceAddressLine1 ?? '',
                        line2: jobLead.serviceAddressLine2,
                        city: jobLead.serviceAddressCity ?? '',
                        stateAbbreviation:
                          jobLead.serviceAddressStateAbbreviation ?? '',
                        zipCode: jobLead.serviceAddressZipCode ?? '',
                      },
                      'google',
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {addressToken}
                  </a>
                ) : null}
              </Item>
            </div>
          </div>
          <div className="job-leads-container-contact-and-job-link-container mt-3 flex">
            <div className="job-leads-container-contact flex-1">
              {jobLead.contactPhoneNumber || jobLead.contactEmailAddress ? (
                <Item title="Contact">
                  {jobLead.contactPhoneNumber && (
                    <div>
                      <a href={`tel:${jobLead.contactPhoneNumber}`}>
                        {jobLead.contactPhoneNumber}
                      </a>
                      <CopyToClipboard
                        payload={jobLead.contactPhoneNumber}
                        className="ml-2 inline-block"
                        label={
                          <span className="flex items-center gap-2">
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                        }
                      />
                    </div>
                  )}
                  {jobLead.contactEmailAddress && (
                    <div>
                      <a href={`mailto:${jobLead.contactEmailAddress}`}>
                        {jobLead.contactEmailAddress}
                      </a>
                      <CopyToClipboard
                        payload={jobLead.contactEmailAddress}
                        className="ml-2 inline-block"
                        label={
                          <span className="flex items-center gap-2">
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                        }
                      />
                    </div>
                  )}
                </Item>
              ) : (
                <Item title="Contact">{null}</Item>
              )}
            </div>
            {jobLead.job && (
              <div className="job-leads-container-converted-job flex-1">
                <Item title="Converted Job">
                  <Link
                    to={CalculatePaths.jobDetails({
                      jobGuid: jobLead.jobGuid,
                    })}
                  >
                    {jobLead.job?.jobType.name} #{jobLead.job?.displayId}
                  </Link>
                </Item>
              </div>
            )}
          </div>
          <div className="job-leads-container-time-created mt-3">
            <TimeAgo prefix="Created " date={jobLead.createdAt} />
          </div>
        </div>
        <div className="job-leads-container-ctas min-w-30 h-15 flex-shrink-0">
          <div className="inline-block">
            <Button
              disabled={isMutatingJobLeadStatus}
              onClick={onClickConvertToJob}
              className={classNames(
                'job-leads-container-ctas-convert-to-job',
                jobLead.jobLeadStatus === JOB_LEAD_STATUS_CONVERTED
                  ? 'invisible'
                  : '',
              )}
              icon={
                <FontAwesomeIcon
                  icon={faCalendarCirclePlus}
                  className="h-[16px] w-[18px]"
                />
              }
            >
              Convert to Job
            </Button>
          </div>
          <div className="ml-2 inline-block">
            <Button
              loading={isMutatingJobLeadStatus}
              onClick={onClickArchive}
              disabled={jobLead.jobLeadStatus === JOB_LEAD_STATUS_ARCHIVED}
              // render the button but as invisible instead of hiding it so that we don't lose layout consistency
              className={classNames(
                'job-leads-container-ctas-archive',
                jobLead.jobLeadStatus === JOB_LEAD_STATUS_CONVERTED
                  ? 'invisible'
                  : '',
              )}
              icon={
                <FontAwesomeIcon
                  icon={faArchive}
                  className="h-[16px] w-[18px]"
                />
              }
            ></Button>
          </div>
        </div>
      </div>
    )
  },
)
