import {
  BzDateFns,
  FullJobType,
  Guid,
  Instant,
  IsoDateString,
  JobAppointmentGuid,
  JobClass,
  JobGuid,
  JobType,
  LocalTime,
  R,
  TechnicianCapacityBlockReasonType,
  UpsertAppointmentAndAssignmentDTO,
  ZoneId,
  ZonedDateTime,
  bzExpect,
  effectiveLocationDisplayName,
  effectiveLocationLongDisplayName,
  formatTechnicianCapacityBlockReasonType,
  nextGuid,
  noOp,
} from '@breezy/shared'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Form, Input, Select, Spin, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ArrivalWindowForm } from '../../components/NewAppointmentModal/UpsertAppointmentForm/ArrivalWindowForm'
import {
  PendingUpsertAppointmentAndAssignmentForm,
  UpsertAppointmentForm,
} from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm'
import { COMPANY_USERS_QUERY } from '../../components/NewAppointmentModal/UpsertAppointmentForm/UpsertAppointmentForm.gql'
import ProgressiveJobCreationModal from '../../components/ProgressiveJobCreationModal/ProgressiveJobCreationModal'
import { RecurrenceFormV2 } from '../../components/RecurrenceForm/RecurrenceFormV2'
import TrpcQueryLoader, {
  isQueryInitiallyLoading,
} from '../../components/TrpcQueryLoader'
import { SwitchField } from '../../elements/Forms/SwitchField'
import { FetchAllJobsForScheduleQuery } from '../../generated/user/graphql'
import { useFetchCompanyAppointmentArrivalWindows } from '../../hooks/fetch/useFetchCompanyAppointmentArrivalWindows'
import { useCanCreateJobs } from '../../hooks/permission/useCanCreateJob'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import {
  DEFAULT_EVENT_COLOR_CONFIG,
  getColorForJobClass,
} from '../../utils/job-utils'
import { StateSetter } from '../../utils/react-utils'
import {
  FixedBlockCalendarEvent,
  FixedNonBlockCalendarEvent,
  NewEvent,
} from './PendingChanges/pendingChanges'
import {
  RecurringChangeForm,
  RecurringChangeType,
} from './RecurringChangeModal'
import { FETCH_JOBS_SCHEDULE_QUERY } from './Schedule.gql'
import {
  AppointmentMap,
  BlockCalendarEvent,
  BzCalendarEvent,
  FullScheduleAppointment,
  TechnicianResource,
  fixMbscDate,
} from './scheduleUtils'
import { TechMultiSelectForm } from './TechMultiSelectForm'

const ARRIVAL_WINDOW_HOURS = 2

type EventType = 'NEW_JOB' | 'NEW_APPOINTMENT' | 'NEW_ASSIGNMENT' | 'BLOCK'
type EventTypeOption = { label: string; value: EventType }
const DEFAULT_EVENT_TYPE_OPTIONS = [
  {
    label: 'New visit for an existing job',
    value: 'NEW_APPOINTMENT',
  },
  {
    label: 'Add a technician to an existing visit',
    value: 'NEW_ASSIGNMENT',
  },
  {
    label: 'Internal event',
    value: 'BLOCK',
  },
] satisfies EventTypeOption[]

const BLOCK_REASONS_OPTIONS = R.values(TechnicianCapacityBlockReasonType).map(
  value => ({
    value,
    label: formatTechnicianCapacityBlockReasonType(value),
  }),
)

// Though I do return the results, the primary purpose of this is to avoid loading spinners. We embed a ton of forms,
// including ones external to this file used in other places in the app. These often fetch their own data and have their
// own loading spinners. This makes for a poor user experience. Between tRPC, urql, and the browser, there's caching
// going on such that if a sub-component fetches any of these things they'll immediately have the data. So instead of
// doing a ton of refactoring work and copy/pasting to extract "dumb" components out of these various forms so we can
// inject our own data, we can do this slightly hacky thing to just make sure they never have to load. We return the
// data (though we won't even use most of it) because in some cases (mostly sub-fetching we do in this file) it is
// simpler to just have the data and pass it through as a prop to a child.
const usePreLoadedData = () => {
  const companyGuid = useExpectedCompanyGuid()

  const jobTypesQuery = trpc.jobTypes['job-types:get'].useQuery()

  const appointmentArrivalWindows = useFetchCompanyAppointmentArrivalWindows()

  const [{ data: companyUsersQueryData, fetching: companyUsersAreFetching }] =
    useQuery({
      query: COMPANY_USERS_QUERY,
      variables: { companyGuid },
    })

  const [{ data: jobsData, fetching: jobsFetching }] = useQuery({
    query: FETCH_JOBS_SCHEDULE_QUERY,
    variables: {
      companyGuid,
    },
  })

  const necessaryDataLoading = useMemo(
    () =>
      R.any(
        query => !query.data && isQueryInitiallyLoading(query),
        [jobTypesQuery, appointmentArrivalWindows],
      ) ||
      companyUsersAreFetching ||
      jobsFetching,
    [
      appointmentArrivalWindows,
      companyUsersAreFetching,
      jobTypesQuery,
      jobsFetching,
    ],
  )

  return useMemo(
    () => ({
      necessaryDataLoading,
      jobTypes: jobTypesQuery.data ?? [],
      appointmentArrivalWindows,
      companyUsersQueryData,
      jobs: jobsData?.jobs ?? [],
    }),
    [
      appointmentArrivalWindows,
      companyUsersQueryData,
      jobTypesQuery.data,
      jobsData,
      necessaryDataLoading,
    ],
  )
}

type NewBlockFormProps = {
  newEvent: BzCalendarEvent
  suppliedNewEvent: NewEvent
  setNewEvent: StateSetter<NewEvent>
  technicians: TechnicianResource[]
}

const NewBlockForm = React.memo<NewBlockFormProps>(
  ({ newEvent, suppliedNewEvent, setNewEvent, technicians }) => {
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setTechs = useCallback(
      (userGuids: Guid[]) => {
        setNewEvent(event => ({
          ...event,
          userGuids,
        }))
      },
      [setNewEvent],
    )

    const setReasonType = useCallback(
      (reasonType: TechnicianCapacityBlockReasonType) =>
        setNewEvent(event => ({
          ...event,
          reasonType,
        })),
      [setNewEvent],
    )

    const isRecurring = !!newEvent.recurring

    const setIsRecurring = useCallback(
      (isRecurring: boolean) =>
        setNewEvent(event => {
          const recurrenceRule = isRecurring
            ? 'FREQ=DAILY;INTERVAL=1'
            : undefined
          return {
            ...event,
            recurrenceRule,
            recurring: recurrenceRule,
          }
        }),
      [setNewEvent],
    )

    const setRRule = useCallback(
      (rrule: string) =>
        setNewEvent(event => ({
          ...event,
          recurrenceRule: rrule,
          recurring: rrule,
        })),
      [setNewEvent],
    )

    const startingDate = useMemo(
      () => fixMbscDate(newEvent.start),
      [newEvent.start],
    )
    const recurringDisabled = !!suppliedNewEvent.recurring

    return (
      <>
        <Form layout="vertical">
          <TechMultiSelectForm
            technicians={technicians}
            selectedTechGuids={
              newEvent.userGuids ?? (suppliedTechGuid ? [suppliedTechGuid] : [])
            }
            onChange={setTechs}
          />
          <Form.Item label="Event Type">
            <Select
              className="w-full"
              id="block-type-select"
              placeholder="Select a type"
              value={newEvent.reasonType}
              onChange={setReasonType}
              options={BLOCK_REASONS_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[
              {
                required:
                  newEvent.reasonType ===
                  TechnicianCapacityBlockReasonType.OTHER,
              },
            ]}
          >
            <Input.TextArea
              rows={3}
              placeholder="Description of the event"
              value={newEvent.reasonDescription}
              onChange={e =>
                setNewEvent(event => ({
                  ...event,
                  reasonDescription: e.target.value
                    ? e.target.value
                    : undefined,
                }))
              }
            />
          </Form.Item>

          <div
            className={classNames(
              'flex flex-row items-center space-x-3 border-0 border-t border-solid border-bz-border-secondary py-6',
              recurringDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
            onClick={() => !recurringDisabled && setIsRecurring(!isRecurring)}
          >
            <SwitchField
              withIcons
              name="isRecurring"
              value={isRecurring}
              disabled={recurringDisabled}
            />
            <div className="font-semibold">This event repeats</div>
          </div>
        </Form>
        {isRecurring && (
          <RecurrenceFormV2
            rrule={`${newEvent.recurring ?? ''}`}
            onChange={setRRule}
            startingDate={startingDate}
          />
        )}
      </>
    )
  },
)

type NewAssignmentFormProps = {
  newEvent: NewEvent
  suppliedNewEvent: NewEvent
  setNewEvent: StateSetter<NewEvent>
  technicians: TechnicianResource[]
  allAppointments: FullScheduleAppointment[]
}

const NewAssignmentForm = React.memo<NewAssignmentFormProps>(
  ({
    suppliedNewEvent,
    newEvent,
    setNewEvent,
    technicians,
    allAppointments,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setTechs = useCallback(
      (userGuids: Guid[]) => {
        setNewEvent(event => ({
          ...event,
          userGuids,
        }))
      },
      [setNewEvent],
    )

    const setAppointmentGuid = useCallback(
      (appointmentGuid: JobAppointmentGuid) =>
        setNewEvent(event => ({
          ...(event as FixedNonBlockCalendarEvent),
          userGuids: suppliedNewEvent.resource
            ? [`${suppliedNewEvent.resource}`]
            : [],
          appointmentGuid,
          assignmentGuid: nextGuid(),
        })),
      [setNewEvent, suppliedNewEvent.resource],
    )

    const selectOptions = useMemo(() => {
      const options: {
        value: string
        label: string
      }[] = []

      for (const appointment of allAppointments) {
        const appointmentStart = BzDateFns.parseISO(
          appointment.appointmentWindowStart,
          tzId,
        )
        const eventStart = BzDateFns.parseISO(newEvent.start, tzId)
        if (!BzDateFns.isSameDay(appointmentStart, eventStart)) {
          continue
        }
        const appointmentEnd = BzDateFns.parseISO(
          appointment.appointmentWindowEnd,
          tzId,
        )
        options.push({
          value: appointment.appointmentGuid,
          label: `${appointment.job.pointOfContact.fullName} - ${
            appointment.job.location.address.line1
          }, ${appointment.job.location.address.zipCode} - ${BzDateFns.format(
            appointmentStart,
            'h:mma',
          )} -> ${BzDateFns.format(appointmentEnd, 'h:mma')}`,
        })
      }
      return options
    }, [allAppointments, newEvent.start, tzId])

    const validTechnicianGuids = useMemo(() => {
      const appointment = allAppointments.find(
        appointment => appointment.appointmentGuid === newEvent.appointmentGuid,
      )
      const alreadyAssignedTechGuidMap: Record<string, true> = {}
      for (const assignment of appointment?.assignments ?? []) {
        alreadyAssignedTechGuidMap[assignment.technicianUserGuid] = true
      }
      return technicians.filter(
        tech => !alreadyAssignedTechGuidMap[tech.userGuid],
      )
    }, [allAppointments, newEvent.appointmentGuid, technicians])

    return (
      <Form layout="vertical">
        <Form.Item label="Which appointment?">
          <Select
            showSearch
            optionFilterProp="label"
            className="w-full"
            placeholder="Select an appointment"
            value={newEvent.appointmentGuid}
            onChange={setAppointmentGuid}
            options={selectOptions}
          />
        </Form.Item>
        {suppliedTechGuid || !newEvent.appointmentGuid ? null : (
          <TechMultiSelectForm
            techsOnly
            technicians={validTechnicianGuids}
            selectedTechGuids={newEvent.userGuids ?? []}
            onChange={setTechs}
          />
        )}
      </Form>
    )
  },
)

type AppointmentArrivalWindowFormProps = {
  jobClass: JobClass
  defaultStart: IsoDateString
  start?: IsoDateString
  end?: IsoDateString
  onChange: (changes: { start?: IsoDateString; end?: IsoDateString }) => void
  hideHeader?: boolean
  withLabels?: boolean
  hidePresets?: boolean
}

export const AppointmentArrivalWindowForm =
  React.memo<AppointmentArrivalWindowFormProps>(
    ({
      jobClass,
      defaultStart,
      start,
      end,
      onChange,
      hideHeader,
      withLabels,
      hidePresets,
    }) => {
      const appointmentArrivalWindowsQuery =
        useFetchCompanyAppointmentArrivalWindows()
      const tzId = useExpectedCompanyTimeZoneId()

      const arrivalWindowLocalDate = useMemo(
        () =>
          // I'd like to refactor the js-joda out, but the appointment form uses it
          ZonedDateTime.parse(defaultStart)
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalDate(),
        [defaultStart, tzId],
      )

      const arrivalLocalTimeWindow = useMemo(() => {
        if (!start || !end) {
          return {
            arrivalWindowStart: ZonedDateTime.parse(defaultStart)
              .withZoneSameInstant(ZoneId.of(tzId))
              .toLocalTime(),
            arrivalWindowEnd: ZonedDateTime.parse(defaultStart)
              .withZoneSameInstant(ZoneId.of(tzId))
              .toLocalTime()
              .plusHours(ARRIVAL_WINDOW_HOURS),
          }
        }
        return {
          arrivalWindowStart: ZonedDateTime.parse(start)
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalTime(),
          arrivalWindowEnd: ZonedDateTime.parse(end)
            .withZoneSameInstant(ZoneId.of(tzId))
            .toLocalTime(),
        }
      }, [defaultStart, end, start, tzId])

      const setArrivalLocalTimeWindow = useCallback(
        (
          window: {
            arrivalWindowStart: LocalTime
            arrivalWindowEnd: LocalTime
          } | null,
        ) => {
          const offset = ZonedDateTime.ofInstant(
            Instant.now(),
            ZoneId.of(tzId),
          ).offset()
          onChange({
            start: window?.arrivalWindowStart
              .atDate(arrivalWindowLocalDate)
              .toInstant(offset)
              .toString() as IsoDateString | undefined,
            end: window?.arrivalWindowEnd
              .atDate(arrivalWindowLocalDate)
              .toInstant(offset)
              .toString() as IsoDateString | undefined,
          })
        },
        [arrivalWindowLocalDate, onChange, tzId],
      )

      const content = (
        <TrpcQueryLoader
          query={appointmentArrivalWindowsQuery}
          render={appointmentArrivalWindows => (
            <ArrivalWindowForm
              jobClass={jobClass}
              appointmentArrivalWindows={appointmentArrivalWindows}
              arrivalWindowLocalDate={arrivalWindowLocalDate}
              setArrivalWindowLocalDate={() => {}}
              arrivalLocalTimeWindow={arrivalLocalTimeWindow}
              setArrivalLocalTimeWindow={setArrivalLocalTimeWindow}
              setTechnicianAssignments={() => {}}
              withLabels={withLabels}
              hidePresets={hidePresets}
            />
          )}
        />
      )
      return hideHeader ? (
        content
      ) : (
        <Form layout="vertical">
          <Form.Item label="Arrival Window">{content}</Form.Item>
        </Form>
      )
    },
  )

export type UpsertAppointmentFormWrapperProps = {
  jobClass: JobClass
  jobGuid?: JobGuid
  hideJobSelect?: boolean
  onChange?: (data: PendingUpsertAppointmentAndAssignmentForm) => void
}

export const UpsertAppointmentFormWrapper =
  React.memo<UpsertAppointmentFormWrapperProps>(
    ({ jobClass, jobGuid = '', hideJobSelect, onChange }) => {
      return (
        <UpsertAppointmentForm
          mode="create"
          hideTechnicianAssignments
          hideArrivalWindowForm
          hideJobSelect={hideJobSelect}
          showFormCancelSubmitButtons={false}
          justifyFormCancelSubmitButtons="end"
          showDivider={false}
          onCancel={noOp}
          onAppointmentCreated={noOp}
          jobGuid={jobGuid}
          labelClassName=""
          jobClass={jobClass}
          onChange={onChange}
        />
      )
    },
  )

type NewAppointmentFormInfo = {
  formData?: PendingUpsertAppointmentAndAssignmentForm
  selectedJobGuid: JobGuid
  selectedTechs: string[]
}

type NewAppointmentFormProps = {
  suppliedNewEvent: NewEvent
  technicians: TechnicianResource[]
  newAppointmentFormInfo: NewAppointmentFormInfo
  setNewAppointmentFormInfo: StateSetter<NewAppointmentFormInfo>
  jobs: NonNullable<FetchAllJobsForScheduleQuery['jobs']>
}

const NewAppointmentForm = React.memo<NewAppointmentFormProps>(
  ({
    suppliedNewEvent,
    technicians,
    newAppointmentFormInfo,
    setNewAppointmentFormInfo,
    jobs,
  }) => {
    const { selectedTechs, selectedJobGuid } = newAppointmentFormInfo
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setSelectedTechs = useCallback(
      (userGuids: Guid[]) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedTechs: userGuids,
        })),
      [setNewAppointmentFormInfo],
    )

    const setSelectedJobGuid = useCallback(
      (selectedJobGuid: Guid) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedJobGuid,
        })),
      [setNewAppointmentFormInfo],
    )

    const setAppointmentFormData = useCallback(
      (formData: PendingUpsertAppointmentAndAssignmentForm) =>
        setNewAppointmentFormInfo(info => ({ ...info, formData })),
      [setNewAppointmentFormInfo],
    )

    const [jobOptions, jobDetails] = useMemo(() => {
      const options: { label: string; value: string }[] = []
      let jobDetails: (typeof jobs)[number] | undefined = undefined

      for (const job of jobs) {
        options.push({
          label: `${
            job.account.accountDisplayName
          } - ${effectiveLocationDisplayName(job.location)} - ${
            job.jobType.name
          }`,
          value: job.jobGuid,
        })
        if (job.jobGuid === selectedJobGuid) {
          jobDetails = job
        }
      }

      return [options, jobDetails]
    }, [jobs, selectedJobGuid])

    const onArrivalWindowChange = useCallback(
      (window: { start?: IsoDateString; end?: IsoDateString }) => {
        setNewAppointmentFormInfo(
          R.assocPath(['formData', 'arrivalWindow'], window),
        )
      },
      [setNewAppointmentFormInfo],
    )

    return (
      <>
        {suppliedTechGuid ? null : (
          <Form layout="vertical">
            <TechMultiSelectForm
              techsOnly
              technicians={technicians}
              selectedTechGuids={selectedTechs}
              onChange={setSelectedTechs}
            />
          </Form>
        )}

        <Form layout="vertical">
          <Form.Item label="Which job?">
            <Select
              showSearch
              optionFilterProp="label"
              className="w-full"
              placeholder="Select job"
              value={selectedJobGuid || null}
              onChange={setSelectedJobGuid}
              options={jobOptions}
            />
          </Form.Item>
        </Form>
        {jobDetails && (
          <div className="grid-two-auto-columns grid gap-2">
            {(
              [
                ['Account', jobDetails.account.accountDisplayName],
                ['Type', jobDetails.jobType.name],
                [
                  'Location',
                  effectiveLocationLongDisplayName(jobDetails.location),
                ],
                ['Status', jobDetails.jobLifecycleStatus.name],
              ] as const
            ).map(([label, value]) => (
              <div key={label}>
                <h4 className="text-sm">{label}</h4>
                <div className="text-sm">{value}</div>
              </div>
            ))}
          </div>
        )}
        {jobDetails && (
          <>
            <Divider />
            <UpsertAppointmentFormWrapper
              jobGuid={jobDetails.jobGuid}
              jobClass={jobDetails.jobType.jobClass}
              onChange={setAppointmentFormData}
            />
            <AppointmentArrivalWindowForm
              jobClass={jobDetails.jobType.jobClass}
              defaultStart={suppliedNewEvent.start}
              start={newAppointmentFormInfo.formData?.arrivalWindow?.start}
              end={newAppointmentFormInfo.formData?.arrivalWindow?.end}
              onChange={onArrivalWindowChange}
            />
          </>
        )}
      </>
    )
  },
)

type NewJobFormProps = {
  suppliedNewEvent: NewEvent
  technicians: TechnicianResource[]
  newAppointmentFormInfo: NewAppointmentFormInfo
  setNewAppointmentFormInfo: StateSetter<NewAppointmentFormInfo>
  jobTypes: FullJobType[]
  jobType: JobType | undefined
  setJobType: StateSetter<JobType | undefined>
}

const NewJobForm = React.memo<NewJobFormProps>(
  ({
    suppliedNewEvent,
    technicians,
    newAppointmentFormInfo,
    setNewAppointmentFormInfo,
    jobTypes,
    jobType,
    setJobType,
  }) => {
    const { selectedTechs } = newAppointmentFormInfo
    const suppliedTechGuid = suppliedNewEvent.resource
      ? `${suppliedNewEvent.resource}`
      : ''

    const setSelectedTechs = useCallback(
      (techGuids: Guid[]) =>
        setNewAppointmentFormInfo(info => ({
          ...info,
          selectedTechs: techGuids,
        })),
      [setNewAppointmentFormInfo],
    )

    const setAppointmentFormData = useCallback(
      (formData: PendingUpsertAppointmentAndAssignmentForm) =>
        setNewAppointmentFormInfo(info => ({ ...info, formData })),
      [setNewAppointmentFormInfo],
    )

    const ourSelectJobTypeGuid = useCallback(
      (jobTypeGuid: string) => {
        const jobType = jobTypes.find(
          jobType => jobType.jobTypeGuid === jobTypeGuid,
        )
        setJobType(jobType)
      },
      [jobTypes, setJobType],
    )

    const onArrivalWindowChange = useCallback(
      (window: { start?: IsoDateString; end?: IsoDateString }) => {
        setNewAppointmentFormInfo(
          R.assocPath(['formData', 'arrivalWindow'], window),
        )
      },
      [setNewAppointmentFormInfo],
    )

    return (
      <>
        <Form layout="vertical">
          {suppliedTechGuid ? null : (
            <TechMultiSelectForm
              techsOnly
              technicians={technicians}
              selectedTechGuids={selectedTechs}
              onChange={setSelectedTechs}
            />
          )}

          <Form.Item label="Job Type">
            <Select
              showSearch
              optionFilterProp="label"
              className="w-full"
              placeholder="Job Type"
              value={jobType?.jobTypeGuid}
              onChange={ourSelectJobTypeGuid}
              options={jobTypes.map(jobType => ({
                value: jobType.jobTypeGuid,
                label: jobType.name,
              }))}
            />
          </Form.Item>
        </Form>

        {jobType && (
          <>
            <UpsertAppointmentFormWrapper
              hideJobSelect
              jobClass={jobType.jobClass}
              onChange={setAppointmentFormData}
            />
            <AppointmentArrivalWindowForm
              jobClass={jobType.jobClass}
              defaultStart={suppliedNewEvent.start}
              start={newAppointmentFormInfo.formData?.arrivalWindow?.start}
              end={newAppointmentFormInfo.formData?.arrivalWindow?.end}
              onChange={onArrivalWindowChange}
            />
          </>
        )}
      </>
    )
  },
)

type NewEventModalProps = {
  onClose: (newEvent?: NewEvent) => void
  pendingNewEvent: BzCalendarEvent
  technicians: TechnicianResource[]
  appointmentMap: AppointmentMap
  allAppointments: FullScheduleAppointment[]
}

export const NewEventModal = React.memo<NewEventModalProps>(
  ({
    onClose,
    pendingNewEvent,
    technicians,
    appointmentMap,
    allAppointments,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const sanitizedNewEvent = useMemo<NewEvent>(
      () => ({
        ...pendingNewEvent,
        start: fixMbscDate(pendingNewEvent.start),
        end: fixMbscDate(pendingNewEvent.end),
      }),
      [pendingNewEvent],
    )
    const description = useMemo(() => {
      const start = BzDateFns.parseISO(sanitizedNewEvent.start, tzId)
      const end = BzDateFns.parseISO(sanitizedNewEvent.end, tzId)

      return (
        <div>
          You're creating a new event on{' '}
          <strong>{BzDateFns.format(start, 'MMMM d, yyyy')}</strong> from{' '}
          <strong>{BzDateFns.format(start, 'h:mma').toLowerCase()}</strong> to{' '}
          <strong>{BzDateFns.format(end, 'h:mma').toLowerCase()}</strong>.
        </div>
      )
    }, [sanitizedNewEvent.end, sanitizedNewEvent.start, tzId])
    const canCreateJobs = useCanCreateJobs()

    const [newEvent, setNewEvent] = useState(sanitizedNewEvent)
    const [eventType, setEventTypeRaw] = useState<EventType>(
      canCreateJobs ? 'NEW_JOB' : 'NEW_APPOINTMENT',
    )

    const defaultNewAppointmentFormInfo = useMemo(
      () => ({
        selectedJobGuid: '',
        selectedTechs: newEvent.resource ? [`${newEvent.resource}`] : [],
      }),
      [newEvent.resource],
    )

    const [newAppointmentFormInfo, setNewAppointmentFormInfo] =
      useState<NewAppointmentFormInfo>(defaultNewAppointmentFormInfo)

    const setEventType = useCallback<StateSetter<EventType>>(
      arg => {
        setNewEvent(sanitizedNewEvent)
        setNewAppointmentFormInfo(defaultNewAppointmentFormInfo)
        setEventTypeRaw(arg)
      },
      [defaultNewAppointmentFormInfo, sanitizedNewEvent],
    )

    const [newJobJobType, setNewJobJobType] = useState<JobType>()

    const enabledEventTypeOptions = useMemo(() => {
      return canCreateJobs
        ? [
            { label: 'New job', value: 'NEW_JOB' },
            ...DEFAULT_EVENT_TYPE_OPTIONS,
          ]
        : [...DEFAULT_EVENT_TYPE_OPTIONS]
    }, [canCreateJobs])

    const { necessaryDataLoading, jobTypes, jobs } = usePreLoadedData()
    const [okText, formContent] = useMemo(() => {
      switch (eventType) {
        case 'NEW_JOB':
          return [
            'Continue',
            <NewJobForm
              suppliedNewEvent={sanitizedNewEvent}
              technicians={technicians}
              newAppointmentFormInfo={newAppointmentFormInfo}
              setNewAppointmentFormInfo={setNewAppointmentFormInfo}
              jobTypes={jobTypes}
              jobType={newJobJobType}
              setJobType={setNewJobJobType}
            />,
          ]
        case 'NEW_APPOINTMENT':
          return [
            'Create visit',
            <NewAppointmentForm
              suppliedNewEvent={sanitizedNewEvent}
              technicians={technicians}
              newAppointmentFormInfo={newAppointmentFormInfo}
              setNewAppointmentFormInfo={setNewAppointmentFormInfo}
              jobs={jobs}
            />,
          ]
        case 'NEW_ASSIGNMENT':
          return [
            'Assign Tech',
            <NewAssignmentForm
              newEvent={newEvent}
              setNewEvent={setNewEvent}
              allAppointments={allAppointments}
              technicians={technicians}
              suppliedNewEvent={sanitizedNewEvent}
            />,
          ]
        default:
          return [
            'Create event',
            <NewBlockForm
              technicians={technicians}
              newEvent={newEvent}
              suppliedNewEvent={sanitizedNewEvent}
              setNewEvent={setNewEvent}
            />,
          ]
      }
    }, [
      eventType,
      sanitizedNewEvent,
      technicians,
      newAppointmentFormInfo,
      jobTypes,
      newJobJobType,
      jobs,
      newEvent,
      allAppointments,
    ])

    const upsertAppointmentMutation =
      trpc.appointments['appointment-and-assignments:upsert'].useMutation()

    const submitDisabled = useMemo(() => {
      if (upsertAppointmentMutation.isLoading || necessaryDataLoading) {
        return true
      }

      const isNewAppointmentFormInfoValid =
        newAppointmentFormInfo.selectedTechs?.length &&
        (newAppointmentFormInfo.formData?.appointmentType !== 'Other' ||
          newAppointmentFormInfo.formData?.description)
      if (eventType === 'BLOCK') {
        return (
          !(newEvent.resource || newEvent.userGuids?.length) ||
          !newEvent.reasonType ||
          (newEvent.reasonType === TechnicianCapacityBlockReasonType.OTHER &&
            !newEvent.reasonDescription)
        )
      } else if (eventType === 'NEW_ASSIGNMENT') {
        return (
          !newEvent.appointmentGuid ||
          !(newEvent.resource || newEvent.userGuids?.length)
        )
      } else if (eventType === 'NEW_APPOINTMENT') {
        return (
          !newAppointmentFormInfo.selectedJobGuid ||
          !isNewAppointmentFormInfoValid
        )
      } else if (eventType === 'NEW_JOB') {
        return !newJobJobType || !isNewAppointmentFormInfoValid
      }
      return false
    }, [
      eventType,
      necessaryDataLoading,
      newAppointmentFormInfo.formData?.appointmentType,
      newAppointmentFormInfo.formData?.description,
      newAppointmentFormInfo.selectedJobGuid,
      newAppointmentFormInfo.selectedTechs?.length,
      newEvent.appointmentGuid,
      newEvent.reasonDescription,
      newEvent.reasonType,
      newEvent.resource,
      newEvent.userGuids?.length,
      newJobJobType,
      upsertAppointmentMutation.isLoading,
    ])

    const [isProgressiveJobModalOpen, setIsProgressiveJobModalOpen] =
      useState(false)

    const newAppointmentUpsertBody = useMemo<UpsertAppointmentAndAssignmentDTO>(
      () => ({
        ...(newAppointmentFormInfo.formData as UpsertAppointmentAndAssignmentDTO),
        arrivalWindow: {
          start:
            newAppointmentFormInfo.formData?.arrivalWindow.start ??
            newEvent.start,
          end:
            newAppointmentFormInfo.formData?.arrivalWindow.end ??
            BzDateFns.withTimeZone(newEvent.start, tzId, date =>
              BzDateFns.addHours(date, ARRIVAL_WINDOW_HOURS),
            ),
        },
        assignments: newAppointmentFormInfo.selectedTechs.map(
          technicianUserGuid => ({
            assignmentGuid: nextGuid(),
            technicianUserGuid,
            timeWindow: {
              start: newEvent.start,
              end: newEvent.end,
            },
          }),
        ),
      }),
      [
        newAppointmentFormInfo.formData,
        newAppointmentFormInfo.selectedTechs,
        newEvent.end,
        newEvent.start,
        tzId,
      ],
    )

    const onOk = useCallback(async () => {
      if (eventType === 'BLOCK') {
        onClose({
          ...(newEvent as FixedBlockCalendarEvent),
          userGuids: newEvent.userGuids ?? [`${newEvent.resource}`],
          color: DEFAULT_EVENT_COLOR_CONFIG.eventColor,
          blockGuid: nextGuid(),
        })
      } else if (eventType === 'NEW_ASSIGNMENT') {
        const appointment = bzExpect(
          appointmentMap[newEvent.appointmentGuid ?? ''],
          'appointment',
          "If we're adding an assignment to an existing appointment, the appointment should exist.",
        )
        onClose({
          ...newEvent,
          userGuids: newEvent.userGuids ?? [`${newEvent.resource}`],
          color: getColorForJobClass(appointment.job.jobType.jobClass)
            .eventColor,
        })
      } else if (eventType === 'NEW_APPOINTMENT') {
        await upsertAppointmentMutation.mutateAsync(newAppointmentUpsertBody)
        onClose()
      } else if (eventType === 'NEW_JOB') {
        setIsProgressiveJobModalOpen(true)
      }
    }, [
      appointmentMap,
      eventType,
      newAppointmentUpsertBody,
      newEvent,
      onClose,
      upsertAppointmentMutation,
    ])

    const onJobCreateSuccess = useCallback(
      async (jobGuid: JobGuid) => {
        await upsertAppointmentMutation.mutateAsync({
          ...newAppointmentUpsertBody,
          jobGuid,
        })
        setIsProgressiveJobModalOpen(false)
        onClose()
      },
      [newAppointmentUpsertBody, onClose, upsertAppointmentMutation],
    )

    return (
      <>
        <OnsiteConfirmModal
          header="Create event"
          onCancel={onClose}
          onConfirm={onOk}
          open={!isProgressiveJobModalOpen}
          confirmText={okText}
          confirmDisabled={submitDisabled}
        >
          {necessaryDataLoading ? (
            <LoadingSpinner />
          ) : (
            <Spin
              spinning={upsertAppointmentMutation.isLoading}
              indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
            >
              {description}
              <Form layout="vertical" className="mt-6">
                <Form.Item label="What kind of event is it?">
                  <Select
                    placeholder="Select an event type"
                    value={eventType}
                    onChange={setEventType}
                    options={enabledEventTypeOptions}
                  />
                </Form.Item>
              </Form>
              <div className="mb-[-24px]">{formContent}</div>
            </Spin>
          )}
        </OnsiteConfirmModal>
        <ProgressiveJobCreationModal
          disableCreateNewAppointment
          isOpen={isProgressiveJobModalOpen}
          setIsOpen={setIsProgressiveJobModalOpen}
          onSuccess={onJobCreateSuccess}
          selectedJobTypeGuid={newJobJobType?.jobTypeGuid}
        />
      </>
    )
  },
)

type EditBlockModalProps = {
  onClose: () => void
  onSubmit: (
    newEvent: BlockCalendarEvent,
    changeType: RecurringChangeType,
  ) => void
  blockEvent: BlockCalendarEvent
  technicians: TechnicianResource[]
}

export const EditBlockModal = React.memo<EditBlockModalProps>(
  ({ onClose, blockEvent, technicians, onSubmit }) => {
    const sanitizedBlockEvent = useMemo<NewEvent>(
      () => ({
        ...blockEvent,
        start: fixMbscDate(blockEvent.start),
        end: fixMbscDate(blockEvent.end),
      }),
      [blockEvent],
    )

    const [recurringChangeType, setRecurringChangeType] =
      useState<RecurringChangeType>('THIS_EVENT_ONLY')

    const [newEvent, setNewEvent] = useState(sanitizedBlockEvent)

    const submitDisabled = useMemo(() => {
      return (
        !(newEvent.userGuids?.length || newEvent.resource) ||
        !newEvent.reasonType ||
        (newEvent.reasonType === TechnicianCapacityBlockReasonType.OTHER &&
          !newEvent.reasonDescription)
      )
    }, [
      newEvent.reasonDescription,
      newEvent.reasonType,
      newEvent.resource,
      newEvent.userGuids?.length,
    ])

    const isEditingRecurrence =
      sanitizedBlockEvent.recurring !== newEvent.recurring
    // If they are editing the recurrence rule, it must always be this and future (it isn't a one-off and it should only
    // be going forward)
    const effectiveRecurringChangeType = isEditingRecurrence
      ? 'THIS_AND_FUTURE'
      : recurringChangeType

    const onOk = useCallback(async () => {
      onSubmit(newEvent as BlockCalendarEvent, effectiveRecurringChangeType)
    }, [effectiveRecurringChangeType, newEvent, onSubmit])

    const recurrenceChangeForm = useMemo(() => {
      if (!newEvent.recurring || !sanitizedBlockEvent.recurring) {
        return null
      }
      const form = (
        <RecurringChangeForm
          changeType={effectiveRecurringChangeType}
          setChangeType={setRecurringChangeType}
        />
      )
      return isEditingRecurrence ? (
        <Tooltip
          title="If you change the recurrence rules, it must apply to this and future events."
          placement="left"
        >
          <span>
            <div className="pointer-events-none opacity-50">{form}</div>
          </span>
        </Tooltip>
      ) : (
        form
      )
    }, [
      newEvent.recurring,
      sanitizedBlockEvent.recurring,
      effectiveRecurringChangeType,
      isEditingRecurrence,
    ])

    return (
      <OnsiteConfirmModal
        header="Edit event"
        onCancel={onClose}
        onConfirm={onOk}
        open
        confirmDisabled={submitDisabled}
      >
        <NewBlockForm
          technicians={technicians}
          newEvent={newEvent}
          suppliedNewEvent={sanitizedBlockEvent}
          setNewEvent={setNewEvent}
        />
        {recurrenceChangeForm}
      </OnsiteConfirmModal>
    )
  },
)
